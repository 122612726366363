// Basic styles
// --------------------------------------------------

.alert {
  position: relative;
  padding: 30px;
  margin-bottom: 30px;
  border: 3px solid transparent;
  border-radius: 1px;
  font-weight: 300;
  font-size: 15px;
  hr {
    border-color: $color3;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  font: normal 600 20px $font1;
  margin: 0 0 20px;
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Contextual message
// --------------------------------------------------

.alert-dismissible {
  // Adjust close link position
  .close {
    position: absolute;
    opacity: .65;
    top: 26px;
    right: 22px;
    padding: 0;
    color: inherit;
    cursor: pointer;
    svg {
      color: $color2;
      width: 14px;
      height: 14px;
    }
  }
}

.alert-primary {
  background: $color-info;
  border-color: $color-info;
  .alert-link {
    color: $color1;
  }
}

.alert-success {
  border-color: $color-success;
  .alert-heading {
    color: #313928;
  }

  .alert-link {
    color: darken($color-success, 10%);
  }
}


.alert-danger {
  border-color: $color-error;
  color: $color-error;
  .alert-heading {
    color: $color-error;
  }

  .alert-link {
    color: darken($color-error, 10%);
  }
}
