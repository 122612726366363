.pro {
  &-intro{
    max-width: 660px;
    color: $EmperorGrey;
    font-family: $playfair;
    line-height: 1.8;

    @include media-breakpoint-up(lg){
      font-size: 18px;
    }

  }

  .abo-main-photo {
    img {
      width: 80%;
    }
    text-align: center;
    width: 100%;
  }

  .abo-tableau-tarifs {
    text-align: center;
    @include media-breakpoint-up(sm){
      font-size: 1.5rem;
    }
    img {
      max-width: 100%;
    }
    .pourcentage-remise {
      font-weight: bold;
      color: $color1
    }
  }

  &-platform{
    &:nth-of-type(odd){
      .pro-platform{
        &-second{
          .pro-platform-quote{
            @include media-breakpoint-up(md){
              margin-left: 70px;
              margin-right: 50px;
            }
          }
        }
      }
    }

    &:nth-of-type(even){
      .pro-platform{
        &-title{
          text-align: right;
          &.h1-like small {
            padding-right: 0;
          }
        }
        &-first{
          .pro-platform-text{
            @include media-breakpoint-up(lg){
              order: -1;
            }
          }
        }
        &-second{
          justify-content: flex-end;
          .pro-platform-quote{
            @include media-breakpoint-up(md){
              order: 1;
              margin-left: 50px;
              margin-right: 70px;
            }
          }
        }
        &-third{
          margin-left: auto;
        }
      }
    }

    &-visuel{
      max-width: 450px;

      @include media-breakpoint-down(sm){
        order: -1;
        width: 100vw;
        left: -15px;
      }
      @include media-breakpoint-only(md){
        max-width: 300px;
      }
    }

    &-text{
      .facebook &{
        margin-top: -45px;
        @include media-breakpoint-down(md){
          margin-top: -20px;
        }
      }
      color: $GrayGrey;
      font-family: $playfair;
      line-height: 2;
      margin: 0;
      flex: 1;

      &.pro-platform-specs {
        font-size: .8em;
        margin: auto;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }

      @include media-breakpoint-up(lg){
        margin: -80px auto 0;
        max-width: 410px;
      }
    }

    &-quote{
      font-size: 26px;
      color: $RedCarnation;
      font-weight: 900;
      max-width: 280px;
      line-height: 1.3;
      letter-spacing: -1.5px;
      font-style: italic;
      margin: 0;

      @include media-breakpoint-down(sm){
        padding: 30px 0;
      }

      @include media-breakpoint-only(md){
        font-size: 30px;
      }

      @include media-breakpoint-up(md){
        margin-top: -70px;
        font-size: 36px;
      }

      &:before{
        /* content: "«"; */
      }
      &:after{
        /* content: "»"; */
      }
    }

    &-second{
      .facebook &{
        margin-top: -35px;
      }
      .instagram &{
        margin-top: -55px;
        @include media-breakpoint-only(sm){
          margin-top: -20px;
        }
      }
      position: relative;
      z-index: 1;
      @include media-breakpoint-up(lg){
        margin-top: -65px;
      }
    }

    &-third{
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;

      @include media-breakpoint-only(md){
        margin-top: -60px;
      }

      @include media-breakpoint-up(md){
        max-width: 300px;
        margin-top: -150px;
      }

      .facebook &{
        margin-top: -60px;
        @include media-breakpoint-only(md){
          margin-top: -90px;
        }
        @include media-breakpoint-up(md){
          margin-top: -165px;
        }
      }
    }

    &-reassurance{
      margin-top: -70px;
      position: relative;

      @include media-breakpoint-only(md){
        margin-top: -140px;
      }

      @include media-breakpoint-up(lg){
        margin-top: -230px;
      }
      &:after{
        content: "";
        height: 100%;
        background: $BeigeWood;
        top:0;
        width: 100vw;
        position: absolute;
        display: block;
        z-index: -1;
        left: -15px;
        @include media-breakpoint-only(sm){
          left: calc(-100vw / 2 + 495px / 2);
        }
        @include media-breakpoint-only(md){
          left: calc(-100vw / 2 + 675px / 2);
        }
        @include media-breakpoint-only(lg){
          left: calc(-100vw / 2 + 915px / 2);
        }
        @include media-breakpoint-up(lg){
          left: calc(-100vw / 2 + 1095px / 2);
        }
      }

      .facebook &{
        margin-top: -160px;
      }

      .instagram &{
        margin-top: -150px;
      }

      .twitter &{
        margin-top: -155px;
        @include media-breakpoint-only(sm){
          margin-top: -105px;
        }
      }

      .blog & {
        height: 240px;
        margin-top: -180px;

        @include media-breakpoint-up(md){
          margin-top: -120px;
          height: 200px;
        }
      }


      &__wrapper{
        padding-bottom: 20px;
        padding-top: 120px;

        @include media-breakpoint-up(md){
          padding-top: 170px;
        }

        .facebook &{
          padding-top: 140px;
        }
      }
      &__list{
        margin: 0;

        @include media-breakpoint-down(md){
          display: flex;
          justify-content: center;
        }

        .item{
          @include media-breakpoint-down(md){
            padding-left: 50px;
          }
          @include media-breakpoint-up(lg){
            padding-left: 35px;
          }

        }
      }
    }

  }

  .video-block {
    padding: 40px 0 0 0;
    display: flex;
    align-items: start;

    @include media-breakpoint-only(md){
      padding: 55px 0 20px 0;
    }

    @include media-breakpoint-up(lg){
      padding: 70px 0;
    }

    @include media-breakpoint-down(sm){
      flex-direction: column;
      align-items: stretch;
      .subscription-cta {
        margin-left: 0;
        margin-top: 15px;
        flex-basis: 150px;
        .square {
          padding-bottom: 0;
          height: 100%;
          & > div {
            height: 100%;
            padding: 0 10%;
          }
        }
      }
    }

    .subscription-cta {
      margin-left: 7.5%;
      color: white;
      text-align: center;
      background-color: $color1;
      flex-grow: 1;

      a {
        color: white;
        outline: none;
      }

      .square {
        padding-bottom: 100%;
        position: relative;
        & > div {
          padding: 10%;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .arrow {
            text-align: right;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
      .title {
        font-weight: bold;
        font-size: 2.3rem;
        @include media-breakpoint-only(lg){
          font-size: 1.8rem;
        }
        @include media-breakpoint-only(md){
          font-size: 1.4rem;
        }
      }
      .body {
        font-family: Playfair Display,Georgia,Times New Roman,Times,serif;
        @include media-breakpoint-down(lg){
          font-size: 0.943rem;
        }
        @include media-breakpoint-down(md){
          font-size: 0.9rem;
        }
      }

    }
  }

  &-video{
    width: 64%;
    @include media-breakpoint-down(sm){
      width: 100%;
    }

    &-iframe{
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      width: 100%;

      iframe{
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &-clients {
    position: relative;
    margin-top: 25px;
    &:after{
      content: "";
      height: 100%;
      background: $BeigeWood;
      top:0;
      width: 100vw;
      position: absolute;
      display: block;
      z-index: -1;
      left: -15px;
      @include media-breakpoint-only(sm){
        left: calc(-100vw / 2 + 495px / 2);
      }
      @include media-breakpoint-only(md){
        left: calc(-100vw / 2 + 675px / 2);
      }
      @include media-breakpoint-only(lg){
        left: calc(-100vw / 2 + 915px / 2);
      }
      @include media-breakpoint-up(lg){
        left: calc(-100vw / 2 + 1095px / 2);
      }
    }
  }

  &-form-text{
    color: $GrayGrey;
    font-family: $playfair;
  }

  &-tarifs{
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }

}
