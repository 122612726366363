//  Colors
//  pour renommer les couleurs (http://chir.ag/projects/name-that-color)
// ===================================================================

$RedCarnation: #f64e60;
$BlackMine: #242424;
$BeigeSatin: #e9e5de;
$BeigeWood : #f5f2ed;

$GreenAtlantis: #46b066;
$RedMonza: #cd0037;
$CitrineWhite: #fbfad9;

$SpringWood: #fbfaf8;

$GrayGrey: #7f7f7f;
$EmperorGrey: #525151;
$TundoraGrey: #4a4a4a;
$CotonSeedGrey: #c4bfb5;
$NobelGrey: #b7b7b7;
$WestarGrey: #e1ddd6;
$AbbeyGrey: #515356;
$NaturalGrey: #918E88;
$TeaBeige: #beb8ad;
$CottonBeige: #c4bfb5;
$Gray: #8b8b8b;
$SatinLinen: #e6e1d9;

$color1: $RedCarnation;
$color2: $BlackMine;
$color3: $BeigeSatin;
$color4: $BeigeWood;

$color-success: $GreenAtlantis;
$color-error: $RedMonza;
$color-info: $CitrineWhite;

$darkcolor1: $GrayGrey;
$darkcolor2: $EmperorGrey;
$darkcolor3: $TundoraGrey;
$darkcolor4: $AbbeyGrey;
$darkcolor5: $NaturalGrey;

$lightcolor1: $CotonSeedGrey;
$lightcolor2: $NobelGrey;
$lightcolor3: $WestarGrey;
$lightcolor4: $TeaBeige;
$lightcolor5: $CottonBeige;
$lightcolor6: $Gray;
$lightcolor7: $SatinLinen;
$lightcolor8: $SpringWood;

$shadowcolor: lighten($lightcolor1, 20%);


//  Fonts
// ===================================================================


$montserrat : "Montserrat", "Helvetica Neue", Helvetica, Arial, "Nimbus Sans L", sans-serif;
$playfair : "Playfair Display", Georgia, "Times New Roman", Times, serif;

$font1 : $montserrat;
$font2 : $playfair;
