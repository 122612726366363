body,
input,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font1;
  font-weight: 400;
  color: $color2;
}


body,
html {
  &.about.menu-is-open,
  &.home.menu-is-open,
  &.blookspace-home.menu-is-open,
  &.menu-is-open {
    position: relative;
    height: 100%;
    overflow: hidden;
    .btn-menu {
      z-index: 8001;
      &::before,
      &:after  {
        opacity: .3;
        background: #fff;
        box-shadow: none;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(135deg);
      }
    }
  }
  @include media-breakpoint-up(md) {
    &.menu-is-open {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}


ul {
  list-style-type: none;
}


.about,
.blookspace-home,
.home {
  .site-header {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

.main-content {
  min-height: 50vh;
  padding-bottom: 50px;
  @include media-breakpoint-up(md) {
    padding-bottom: 140px;
  }
}

.title-section {
  margin: 0 0 60px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 28px;
  line-height: 1.1;
  font-family: $font2;
  font-style: italic;
  font-weight: 700;
  color: $color2;
  text-align: center;
  @include media-breakpoint-up(md) {
    font-size: 36px;
  }
}


%baseline {
  font-weight: 300;
  font-size: 14px;
  text-indent: -22px;
  letter-spacing: -.35px;
}
