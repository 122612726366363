.fixed-topbar {
  z-index: 50;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  background: $color2;
  transition: transform .3s;
  transform: translate(0,100%);

  &.product-topbar{
    bottom: 0;
    top: auto;
    transform: translateY(70px);

  }

  &.is-fixed {
    transform: translate(0,0);
  }

  .row {
    height: 100%;
    margin: 0;
  }
  @include media-breakpoint-up(md) {
    top: 0;
    bottom: auto;
    height: 70px;
    transform: translate(0,-100%);
  }
}

.fixed-topbar__left {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
    padding-left: 20px;
    font-weight: 300;
    font-size: 12px;
    color: $darkcolor1;
    .title {
      font-size: 17px;
      font-weight: 400;
      color: #fff;
    }
    .infos {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    .infos {
      display: inline-block;
      position: relative;
      margin-left: 20px;
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: -12px;
        height: 24px;
        width: 1px;
        background: rgba($darkcolor1, .3);
        transform: translate(0,-50%);
      }
    }
  }
}

.fixed-topbar__right {
  padding: 0;
  .btn {
    min-height: 60px;
    width: 100%;
    border-radius: 0;
    padding: 23px 25px;
    margin: 0;
  }
  .product-resume__price {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    padding: 0 15px;
    text-align: right;
    .btn {
      width: auto;
      margin: 0 -15px 0 0;
    }
    .product-resume__price {
      display: inline-block;
      position: relative;
      top: 10px;
      border: 0;
      padding: 0 10px;
      margin: 0;
      font-size: 28px;
      color: #fff;
    }
  }
  @include media-breakpoint-up(md) {
    .btn {
      padding: 28px 25px;
    }
  }
}
