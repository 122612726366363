
button {
  padding: 0;
  border: none;
  background: none;
  transition: color .25s;
  &.disabled {
    opacity: .4;
    cursor: not-allowed;
    &:hover,
    &:focus {
      color: $color2;
    }
  }
  &:hover,
  &:focus {
    color: $color1;
  }
}

.btn {
  display: inline-block;
  margin: 5px auto;
  padding: 13px 20px 12px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  border-radius: 6px;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  text-transform: uppercase;
  transition: color .25s, background .25s, border-color .25s;
  .picto {
    transition: color .25s;
    font-size: 18px;
  }
  &.lower {
    text-transform: none;
  }
  &.fs-14 {
    font-size: 14px;
  }

  &.f-600 {
    font-weight: 600;
  }
  &:hover,
  &:focus {
    color: $color1;
  }
  &:active {
    box-shadow: none;
  }

  &.arrow-left {
    &:before {
      content: '';
      display: inline-block;
      background: url('../svg/arrow-left.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  &.btn-light {
    color: #111;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    &:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
      color: #111;
      background-color: #dae0e5;
      border-color: #d3d9df;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }
    &:hover {
      color: #111;
      background-color: #e2e6ea;
      border-color: #dae0e5;
    }
  }
}

.btn-disabled {
  cursor: not-allowed;
  text-transform: uppercase;
  color: $lightcolor3 !important;
  background: $lightcolor2 !important;
  border: 1px solid $lightcolor2 !important;
  &:hover,
  &:focus,
  &:active {
    color: $lightcolor3 !important;
    background: $lightcolor2 !important;
    border-color: $lightcolor2 !important;
  }
}

.btn-primary {
  text-transform: uppercase;
  color: #fff;
  background: $color1;
  border: 1px solid $color1;
  .picto {
    position: relative;
    top: -2px;
    display: inline-block;
    font-size: 20px;
    margin-right: 5px;
    line-height: 1;
    transition: color .25s;
    color: #fff;
  }

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    background: darken($color1, 6%);
    border-color: darken($color1, 6%);
    outline: 0;
  }
  &:visited {
    color: #fff;
    background: $color1;
    border-color: $color1;
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background: darken($color1, 6%);
      border-color: darken($color1, 6%);
      outline: 0;
    }
  }
  &.disabled {
    background: $lightcolor2;
    border: 1px solid $lightcolor2;
    cursor: not-allowed;
    &:hover,
    &:focus {
      text-decoration: none;
      background: darken($lightcolor2, 6%);
      border-color: darken($lightcolor2, 6%);
      outline: 0;
    }
  }
}
.btn-secondary {
  text-transform: uppercase;
  color: #fff;
  background: $color2;

  .picto {
    position: relative;
    top: -2px;
    display: inline-block;
    font-size: 20px;
    margin-right: 5px;
    line-height: 1;
    transition: color .25s;
    color: #fff;
  }
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    background: darken($color2, 6%);
    outline: 0;
  }
  &:visited {
    color: #fff;
    background: $color2;
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background: darken($color2, 6%);
      outline: 0;
    }
  }
}


.btn-border {
  color: $darkcolor2;
  border: 1px solid $darkcolor2;
  transition: background .25s, color .25s, border-color .25s;
  background: transparent;
  &:hover,
  &:focus {
    color: $darkcolor2;
  }
  &.btn-border-white {
    color: #fff;
    border: 1px solid #fff;
    &:hover,
    &:focus {
      background: $color1;
      border-color: $color1;
    }
  }
}


.btn-block {
  display: block;
  width: 100%;
}

.btn-link {
  text-decoration: underline;
  text-transform: uppercase;
  color: $color1;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.btn-ico {
  letter-spacing: .4px;
  font-size: 10px;
  color: $darkcolor1;
  text-align: center;
  svg {
    display: block;
    color: $color1;
    width: 20px;
    height: 20px;
    margin: 0 auto 10px;
  }
  @include media-breakpoint-up(md) {
    font-size: 12px;
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.btn-small {
  padding: 10px 15px;
  text-transform: none;
}

.btn-nav {
  background-color: #242424;
  color: #fff;
  &.active {
    background-color: $color1;
  }
}
