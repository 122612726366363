.bloc-testimony {
  margin: 15px;
  color: #fff;
  text-align: center;
  background: $color1;
  .item {
    display: inline-block;
    list-style: none;
  }
  blockquote {
    margin: 0 auto;
    padding: 0 30px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 700;
    font-family: $font2;
    font-style: italic;
  }
  footer {
    font-family: $font1;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  cite {
    display: block;
    margin: 35px 0 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
  }
  @include media-breakpoint-up(sm) {
    blockquote {
      font-size: 26px;
      padding: 0 70px;
    }
  }
  @include media-breakpoint-up(md) {
    overflow: hidden;
    margin: -40px 0 0;
    background: none;
    blockquote {
      max-width: 780px;
      padding: 0 90px;
      font-size: 32px;
    }
  }
}

.bloc-testimony__list {
  display: block;
  padding: 70px 0 40px;
  margin: 0;
  background: $color1;
  @include media-breakpoint-up(md) {
    position: relative;
    padding: 110px 0 65px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      width: 100%;
      background: $color1;
    }
  }
}

