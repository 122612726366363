.bloc-emphasis {
  margin: 40px auto 60px;
  max-width: 500px;
  padding: 30px;
  border: 3px solid $color3;
  text-align: center;
  .title {
    margin-top: 0;
    font: normal 400 22px/1 $font1;
  }
}
