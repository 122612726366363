.platform {
  border: 2px solid $color4;
  padding: 0;
}

.platform__header {
  padding: 20px 30px 15px;

  @include media-breakpoint-up(sm){
    padding: 40px 30px 15px;
  }
}
.platform__body {
  padding: 20px;

  @include media-breakpoint-only(xs) {
    padding-top: 0;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }

  .platform__simple {
    text-align: center;
  }
  form{
    .control-label{
      padding: 0px 10px;
      margin-bottom:20px;
      position: relative;
      top: -4px;
      font-weight:700;
    }

    input {
      max-width: none;
      margin: 0;
    }

    .fullwidth p{
      text-align:left;
    }
    .label-date{
      text-align:left;
      min-width:0px;
      font-weight:700;
    }
    .label-date,
    .input-date{
      margin-right:20px;
      display: inline-block;

    }
  }
  div.fullwidth{
    width:100%;
    margin-bottom: 30px;
    label:not(.control-label) {
      margin: 0;
      display: block;
      min-width: 0;
      text-align: left;
      position: relative;
    }
  
  }

  #browser-list{
    @include media-breakpoint-down(sm) {

      td{
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;

        &:first-child{
          width: 50px;
          text-align: center;
        }

        &:last-child{
          vertical-align: middle;
          width: 50px;
        }
      }

      img{
        width: 24px;
      }

      strong{
        font-size: 14px;
      }

      .download-icon{
        fill: $RedCarnation;
        width: 24px;
        height: 22px;
      }

    }
  }

  #id_blog_choice {
    padding-left: 0;

    [type=radio] {
      position: absolute;
      left: 0;
      top: 4px;
    }

    label {
      padding-left: 25px;
    }

    li{
      word-break: break-word;
      &:not(:first-child) {
        padding-top: 10px;
      }
    }
  }
}
.plateform-privacy-note{
  height: 170px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.blog-choice-image-radio{
  margin:0;
  padding:0 20px 0 20px;  
  display: flex; 
  flex-wrap: wrap; 
    li{
      width: 50%;
      text-align:center;
      margin-bottom:30px;

      @include media-breakpoint-only(md) {
        width: calc(100% / 4);
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% / 5);
      }

      label{
        padding:5px;
        border-radius:3px;
      }
      label.selected-book{
          border:solid 1px #f64e60;
      }
      span{
        display:block;
        font-size:0.7em;
        text-align:center;
      } 
      img{
        display:block;
        margin:0 auto 5px;
      }
      input{
        display:none;
      }
    }
}
.platform__footer {
  display: flex;
  align-items: center;
  transition: background-color 0.5s ease;
  background-color: $color4;

  & > a{
    display: block;
    width: 100%;
    padding: 20px;
  }

  &:hover {
    background-color: darken($color4,5);
  }
}
