.bloc-faq {
  padding: 40px 0;
  .title-section {
    font-size: 24px;
    color: $darkcolor2;
  }
  h2 {
    max-width: 750px;
    margin: auto;
  }
  @include media-breakpoint-up(md) {
    .title-section {
      font-size: 32px;
    }
  }
}

.bloc-faq__list {
  max-width: 750px;
  margin: auto;
  padding-top: 10px;
  .item {
    margin: 20px;
    border-bottom: 1px solid $color3;
  }
}

.bloc-faq__question {
  display: block;
  position: relative;
  padding: 0 15px 0 35px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  color: $darkcolor1;
  transition: all .3s;
  &:hover,
  &:focus {
    color: $color1;
    text-decoration: none;
  }
  svg {
    position: absolute;
    color: $color1;
    top: 50%;
    left: 10px;
    width: 14px;
    height: 14px;
    transform: translate(0,-50%);
    transition: all .3s;
  }
  &[aria-expanded="false"] {
    color: $darkcolor1;
  }
  &[aria-expanded="true"] {
    color: $color1;
    svg {
      color: #cbcbcb;
      transform: translate(0,-50%) rotate(-90deg);
    }
  }
  @include media-breakpoint-up(md) {
    padding: 0 15px 0 40px;
  }
}

.bloc-faq__answer {
  padding: 0 15px 0 35px;
  p {
    font-size: 14px;
    line-height: 1.9;
    //font-family: $font2;
    color: $darkcolor1;
  }
  @include media-breakpoint-up(md) {
    padding: 0 15px 0 40px;
  }
}
