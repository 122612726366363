.blookspace {
  .blook-detail-wrapper {
    .product-card__img {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .price-tag {
      text-align: center;
      background: #fff;
      padding: 6px 15px;
      font-size: 1.5em;
      border-radius: 6px;
      margin-bottom: 5px;
      display: inline-block;
    }
  }
  .modal-backdrop:not(.show) {
    display: none;
  }
  .engagement {
    border: 2px solid #78736f;
    background: #fff;
    padding: 20px;
    font-size: small;
    @include media-breakpoint-down(sm) {
      min-width: 100%
    }
  }

  .main-content {
    padding-top: 60px;
  }
  .page-content {
    background-color: $BeigeWood;
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .blook-header {
    padding-bottom: 40px;
    .blook-description {
      word-wrap: break-word;
      .placeholder {
        color: #858585;
        font-style: italic;
        font-size: small;
        line-height: 2rem;
      }
      #btn-description {
        background: #fff;
        padding: 8px;
        border-radius: 50%;
        font-size: 0;
        position: absolute;
        margin-left: -35px;
        cursor: pointer;
      }
      @include media-breakpoint-down(sm) {
        text-indent: 35px;
        #btn-description {
          margin-top: -5px;
        }
      }

      #description-form-wrapper > form > div {
        text-align: center;
        .form-group {
          text-align: left;
          margin-bottom: 20px;
          margin-top: 25px;
        }
        textarea {
          background: #fff;
        }
      }
    }
  }

  .blook-headline {
    font-weight: bold;
  }

  .bloc-acheter {
    display: flex;
    justify-content: center;
    align-items: stretch;

    & .price {
      flex-shrink: 0;
      padding: 15px;
      background-color: white;
      font-size: 1.5em;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    .form-cart button {
      height: 100%;
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  .bloc-resume-book__infos {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    @include media-breakpoint-down(sm) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      order: 1000;
      margin-top: 10px;
      max-width: 90%;
    }

    background: #e6e0da;
    display: flex;

    & > div {
      font-size: 0.8em;
      width: 138px;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0.4;
      flex-shrink: 1;
      text-align: center;
      padding: 13px 0;
    }

    & span {
      text-align: center;
      margin: auto;
    }

    svg {
      height: 25px;
      width: 25px;
    }

  }

  .support-your-local-hero {
    p {
      padding: 10px 70px;
      text-align: center;
      font-weight: 600;
      font-size: 1.2rem;
      margin-top: 30px;
    }
    .support-actions {
      display: flex;
      flex-direction: column;
      //margin-top: 30px;
      flex-wrap: wrap;
      align-items: center;

      .share-buttons {
        padding-left: 0;
      }

      .btn {
        //font-size: 19px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .blookspace-link {
        max-width: 60%;
        padding: 8px;
        text-transform: none;
        font-size: 17px;

        @include media-breakpoint-down(sm) {
          min-width: 100%
        }
        span {
          text-align: left;
        }
      }

      img.avatar {
        height: 50px;
        width: 50px;
        margin-right: 8px;
      }

      form.form-cart {
        button {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      & > * {
        flex-grow: 1;
        margin-bottom: 10px;
        @include media-breakpoint-down(sm) {
          width: 100%;
          min-height: 67px;
        }
      }
    }
  }

  .xsell {
    list-style-type: none;
    h2 {
      text-transform: uppercase;
      text-align: center;
    }
    ul {
      display: flex;
      padding: 0;
      justify-content: space-evenly;
      flex-wrap: wrap;
      li {
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-left: 30px;
          margin-right: 30px;
          .product-card__infos {
            display: flex;
            flex-direction: column;
            .btn {
              margin: 5px 0;
            }
          }
        }
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .sales-summary {
    display: flex;
    .container {
      max-width: 100%;
      width: auto;
      padding-left: 80px;
      padding-right: 80px;
      @include media-breakpoint-down(sm) {
        .table-container {
          overflow-x: scroll;
        }
      }
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
      thead {
        padding-bottom: 8px;
        border-bottom: 2px solid #ccc;
      }
      td, th {
        padding: 0 12px;
        text-align: center;
        height: 3em;
      }
      h2 {
        margin-left: -40px;
        @include media-breakpoint-down(md) {
          margin-left: -10px;
        }
      }
      .piggy-container {
        display: flex;
        flex-direction: column;
        .piggy {
          margin: auto;
          margin-top: 25px;
          display: inline-block;
          background-color: $color1;
          padding: 20px 30px;
          border-radius: 10px;
          color: white;
          font-size: 4rem;
        }
        .piggy-caption {
          margin: auto;
          margin-top: 15px;
        }
      }

      .payment-listing {
        @include media-breakpoint-down(xs) {
          align-items: center;
        }

        .payment-listing__item {
          width: auto;
        }

        li {
          margin-bottom: 10px;
        }

        button {
          min-height: 100%;

          &:not(.disabled) {
            cursor: pointer;
          }
        }
      }
    }
  }

  .blookspace-profile-header {
    position: relative;
    //min-height: 250px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    .textual-info {
      background-color: #ffffffc7;
    }
    margin-bottom: 20px;
    padding: 15px 50px;

    @include media-breakpoint-only(xl) {
      min-height: 300px;
    }
    @include media-breakpoint-only(lg) {
      min-height: 250px;
    }
    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }

    button {
      cursor: pointer;
    }

    #avatar-form, #banner-form {
      ul {
        list-style-type: disc;
        color: #999;
      }

      .footer {
        display: flex;
      }
    }

    #blookspace-avatar {
      margin: 0 15px 0 0;
      position: relative;
      width: 120px;
      height: 120px;
      flex: 0 0 auto;

      @include media-breakpoint-only(md) {
        margin: 20px 35px 50px;
      }
      @include media-breakpoint-only(sm) {
        margin: 60px 35px 10px;
      }
      @include media-breakpoint-only(xs) {
        margin: 10vw 5vw 10px;
        max-width: 120px;
        max-height: 120px;
        width: 20vw;
        height: 20vw;
      }


      & > button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffffc7;
        font-size: 0;
        border-radius: 50%;
        padding: 8px;
      }
    }

    #btn-banner {
      padding: 8px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      background-color: #ffffffc7;
      border-radius: 50%;
    }
    #blookspace-banner {
      position: absolute;
      z-index: -10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      .underlay-centerer {
        /* not sure what this is supposed to be for, but it doesn't work with proper cropping...
        width: 2500px;
        height: 2500px;
        */
          position: absolute;
        /*
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        */
        display: flex;
        img {
          margin: auto;
          max-width: 100%;
          @each $breakpoint, $container-max-width in $container-max-widths {
            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                min-width: $container-max-width - 30px; /* - 30;*/
                max-width: $container-max-width - 30px;
            }
          }
        }
      }
    }
    #blookspace-nickname {
      display: inline-flex;
      flex-direction: column;
      //padding-bottom: 0px;
      padding: 4px 10px 0;
      .nickname {
        color: $color1;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    #blookspace-presentation {
      display: flex;
      align-items: center;
      font-style: italic;
      padding: 8px 10px;
      button {
        margin: 4px;
      }
    }

    .main-block {
      display: flex;
      margin: auto 0;
      @include media-breakpoint-down(md) {
        width: 100%;
        flex-direction: column;
      }
    }

    .textual-block {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 0;
        font-size: 0.75rem;

        &.date-joined {
          color: $darkcolor2;
          font-size: 13px;
        }

        &.blookspace-presentation-display {
          font-size: 16px;
        }
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }

    .header-actions {
      margin-left: auto;
    }
  }

  .bsforms {
    display: flex;
    &:not(.show) {
      display: none;
    }
    .form-group {
      margin-bottom: 0;
    }
    label {
      display: none;
    }
    input {
      margin: 5px 20px 10px 0;
      width: auto;
      background-color: white;
      border-color: #242424;
    }
    button {
      font-size: 0.75rem !important;
      padding: 6px 15px;
      margin: 0 5px;
      border-radius: 100px;
    }
  }

  .modal-content form:not(.labelled) {
    padding-top: 15px;
    label {
      display: none;
    }
  }

  #btn-banner {
    font-size: 0px;
  }

  #share-modal {
    background-color: #242424ee;
    .modal-content {
      background-color: #f64e60 /*#0000*/;
      color: #fff;
      .share-buttons {
        padding-left: 0;
        justify-content: space-around;
        display: flex;
      }
    }
  }

  .share-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .btn-secondary {
      padding: 8px 12px;
      font-weight: bold;
    }
  }
}

.blookspace-home {
  .breadcrumb {
    position: absolute;
    z-index: 1;
    top: 110px;
    width: 100%;

    a, span {
      color: white;
    }
  }
  .blookspace-home__hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 240px 0px 180px;
    position: relative;
    color: white;

    @include media-breakpoint-down(sm) {
      padding: 140px 0 150px;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 0;
      pointer-events: none;
    }

    &:before {
      opacity: .6;
      background: linear-gradient(214deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    }
    &:after {
      background: transparentize(#242424, .5);
    }

    .blookspace-home__hero-inner {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .blookspace-home__title {
    color: white;

    .subhead {
      padding: 0;
      color: $color1;
    }
  }

  .blookspace-home__subtitle {
    background-color: white;
    color: $color2;
    font-family: $font2;
    font-size: 28px;
    padding: 0;
    font-weight: 400;
    font-style: italic;
    margin: 0 0 .5rem;
    padding: 4px 8px;
  }

  p {
    font-family: $font2;
  }

  .blookspace-home__spotlight {
    margin-top: -80px;
  }

}

 .blookspace-blooks-listing {
    li {
      margin-bottom: 4rem;
    }
    .tag-wrapper {
      display: block;
      margin: 10px 0 -10px;
    }
    .tag {
      background: $color1;
      color: white;
      border-radius: 4px;
      padding: 5px 8px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 10px;
    }
    .blookspace-blooks-listing__img {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .product-card {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }


.presentation-blookspace {

  .cta-container {
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .reasons {
    display: flex;
    flex-wrap: wrap;
    .reason {
      @include media-breakpoint-down(sm) {
        min-width: 100%;
      }
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 0;
      flex-grow: 1;
      svg {
        color: $color1;
      }
      h3 {
        color: $color1;
        font-style: normal;
        font-family: Montserrat;
        margin-top: 10px;
      }
    }
  }

  .howto {
    .howto-step {
      margin-bottom: 50px;

      display: flex;
      align-items: center;

      .title {
				color: #f64e60;
				font-size: 1.125rem;
				font-family: Montserrat,Helvetica Neue,Helvetica,Arial,Nimbus Sans L,sans-serif;
        font-style: normal;
				font-weight: 300;
				margin-bottom: 10px;
      }

      &:nth-child(2n+1) {
        flex-direction: row-reverse;
        img {
          margin-left: 55px;
          margin-right: 0;
        }
      }
      img {
        max-width: 250px;
        margin-left: 0;
        margin-right: 55px;
      }
      & > div {
        width: 40%;
        font-style: italic;
      }
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        p {
          min-width: 100%;
          margin-bottom: 45px;
          margin-top: 15px;
        }
      }
    }
  }
}
