.bloc-reassurance {
  text-align: center;
  .title-section {
    font-size: 24px;
    color: $darkcolor2;
    padding-top: 20px;
    margin-bottom: 30px;
  }
  .slick-dots {
    li button {
      &::before {
        border-color: darken($color3, 10%);
        background: darken($color3, 10%);
      }
    }
  }
  @include media-breakpoint-up(md) {
    text-align: left;
    // .bloc-reassurance__wrapper {
    //   padding-right: 15px;
    //   padding-right: 15px;
    // }
    .title-section {
      font-size: 32px;
      padding-top: 50px;
      margin-bottom: 60px;
    }
  }
}

.bloc-reassurance__wrapper {
  padding: 50px 0 40px;
  background: $color4;
}

.bloc-reassurance__list {
  padding: 0;
  margin: 65px 0 0;
  .item {
    padding: 0 50px;
    text-align: center;
    list-style: none;
    color: $darkcolor1;
    font-size: 14px;
    font-family: $font2;
  }
  img {
    display: block;
    margin: 0 auto 10px;
    width: 48px;
  }
  .content {
    display: inline-block;
  }
  .title {
    display: block;
    color: $color1;
    font-size: 18px;
    font-family: $font1;
    font-weight: 300;
    margin-bottom: 10px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 40px;
    .item {
      position: relative;
      text-align: left;
      margin-bottom: 50px;
      padding: 38px 25px 20px 85px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        background: $color3;
      }
      &::before {
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
      }
      &::after {
        top: 0;
        left: 0;
        width: 30%;
        height: 1px;
      }
    }
    .title {
      margin-bottom: 0;
    }
    img {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 38px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 0 80px;
    .item {
      padding-right: 85px;
    }
    .content {
      font-size: 14px;
    }
  }
}
