.push-subscribe {
  min-height: 100%;
  padding: 50px 30px 40px;
  margin-top: 30px;
  border: 5px solid $color3;
  .title {
    padding: 0;
    font-weight: 600;
    font-size: 22px;
    color: $color1;
  }
  p {
    font-family: $font2;
    font-size: 14px;
    color: $darkcolor1;
  }
  @include media-breakpoint-up(md) {
    margin-top: 0;
    img {
      display: block;
      margin: 40px auto -60px;
    }
  }
}
