.action-btn {
  padding: 0 20px;
  margin-top: 20px;
  .btn {
    position: relative;
    margin-bottom: 15px;
    padding: 25px 15px;
    font-size: 17px;
    font-weight: 400;
    border-radius: 0;
    text-transform: none;
    svg {
      position: absolute;
      top: 50%;
      right: 25px;
      width: 30px;
      height: 26px;
      transform: translate(0,-50%);
    }
    &:last-child {
      &:not(.single-btn) {
        color: $darkcolor4;
        background: $color4;
        transition: .3s all;
        &:hover,
        &:focus {
          text-decoration: none;
          background: darken($color4, 6%);
        }
        svg {
          right: auto;
          left: 25px;
        }
      }

    }
  }
  @include media-breakpoint-up(md) {
    padding: 0;
    margin-top: 110px;
  }
}
