.bloc-grid-product {
  position: relative;
  img {
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    padding: 5px 10px;
    max-width: 424px;
    img {
      width: auto;
    }
    &.wide {
      max-width: none;
      margin-top: -85px;
      img {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.wide {
      margin-top: -166px;
    }
  }
  @include media-breakpoint-up(xl) {
    &.wide {
      margin-top: -256px;
    }
  }
}

.grid-product-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: left;
  .title {
    padding-top: 0;
    margin: 0 0 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    color: #fff;
  }
  a {
    color: #fff;
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  .size {
    display: block;
    font-size: 20px;
    font-family: $font2;
    font-style: italic;
    color: #fff;
    margin: 0 0 2px;
  }
  .black{
    background-color:black;
    padding:5px 8px;
    display:inline-block !important;
  }
  @include media-breakpoint-up(md) {
    bottom: 30px;
    left: 30px;
    right: 30px;
    .title {
      font-size: 31px;
      max-width: 70%;
    }
  }
}

//  Price textbox
// ===================================================================

.price-textbox {
  .from {
    display: block;
    padding: 2px 10px;
    text-transform: uppercase;
    font-size: 9px;
    color: $darkcolor1;
  }
  .actions {
    display: inline-block;
    color: #fff;
  }
  .inner {
    display: flex;
    span {
      display: inline-block;
      padding: 2px 12px;
      letter-spacing: -1px;
      font-size: 24px;
      font-weight: 300;
      background: $color2;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      &.coming-soon{
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 4px 16px
      }
    }
  }

  .btn-primary {
    color: #fff;
    margin: 0;
    background: $color1;
    transition: .3s all;
    &:focus,
    &:hover {
      background: darken($color1, 7%);
    }
  }
  .product-link{
    padding: 8px 15px;
    margin-top: .5rem;
    margin-bottom: 0;
  }
}
