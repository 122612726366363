//  Tooltip menu
// ===================================================================

.tooltip-blookshelf {
  z-index: 1050;
  position: absolute;
  .preview & {
    right: -124px;
    @include media-breakpoint-up(md){
      right: -5px;
    }
  }
  right: -58px;

  width: calc(100vw - 40px);
  box-shadow: 0 0 4px rgba(#000, .2);
  background: #fff;
  padding: 20px;
  border-radius: 8px;

  @include media-breakpoint-up(md){
    width: 500px;
  }

  &::before, &::after {
    right: 33px;
  }

  .preview &::before {
    @include triangle(12px, #fff, top);
    content: '';
    position: absolute;
    top: -12px;
    right: 126px;

    @include media-breakpoint-up(md){
      right: 15px;
    }

    @media (max-width: 767px) and (orientation: landscape){
      right: 135px;
    }
  }

  .header {
    padding: 10px 0 30px;
    text-align: center;
  }

  &-blooks-container{
    max-height: 300px;
    overflow-y: auto;

    @media (max-width: 767px) and (orientation: landscape){
      max-height: 190px;
    }

    @include media-breakpoint-up(md){
      padding: 0 10px;
      max-height: 500px;
    }
  }

  .blook {
    margin-bottom: 35px;
    text-algin: center;
    a {
      display: flex;
      flex-direction: column;
      height: 100%;


      img {
        width: 100px;
        margin: auto;
      }

      .title {
        text-align: center;
        min-height: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: inherit;
        font-style: inherit;
        font-size: 1rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;
        margin: 0;
        margin-top: 10px;
        color: $darkcolor3;
        word-break: break-word;
      }
    }
  }
}


