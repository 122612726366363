.site-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  svg {
    display: block;
    color: $color2;
  }
  .about &,
  .blookspace-home &,
  .home & {
    svg {
      color: #fff;
    }
    .btn-menu {
      &::before,
      &::after {
        background: #fff;
        box-shadow: 0 10px 0 #fff, 0 -10px 0 #fff;

        @include media-breakpoint-down(sm) {
          width: 24px;
          box-shadow: 0 9px 0 #fff, 0 -9px 0 #fff;
        }
      }
    }
  }
  & > div {
    width: 48%;
  }
  & > .site-main-logo {
    width: 50%;
  }
  @include media-breakpoint-up(md) {
    align-items: start;
    padding: 0 12px;
  }
}

.site-main-logo {
  line-height: 1;
  text-align: center;
  width: 50%;
  color: $color2;
  margin: auto;
  a {
    display: inline-flex;
    &:hover,
    &:focus {
      text-transform: none;
    }
  }
  svg {
    width: 100px;
    height: 30px;
    margin: auto;
  }
  .baseline {
    @extend %baseline;
  }
  .about &,
  .blookspace-home &,
  .home & {
    color: #fff;
    .baseline,
    svg {
      color: #fff;
    }
  }
  @include media-breakpoint-up(md) {
    svg {
      width: 153px;
      height: 40px;
      margin: 35px auto 10px;
    }
  }
}

.site-header__group-buttons {
  .btn-menu {
    position: relative;
    width: 28px;
    height: 28px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      width: 24px;
      height: 2px;
      background: #1f1f1f;
      box-shadow: 0 9px 0 #1f1f1f, 0 -9px 0 #1f1f1f;
      transform: translate(0,-50%);
      transition: transform .2s ease-in, background .2s ease-in;
    }
  }
  button {
    padding: 0;
    border: 0;
    cursor: pointer;
    color: $darkcolor2;
    background: none;
  }
  .account {
    color: $darkcolor2;
  }
  .badge {
    position: absolute;
    top: -4px;
    right: -8px;
    font-size: 11px;
    line-height: 1.9;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    color: #fff;
    background: $color1;
  }
  svg {
    width: 32px;
    height: 32px;
  }
  &:last-child {
    text-align: right;
  }
  @include media-breakpoint-up(md) {
    margin-top: 40px;
    &:last-child {
      padding-right: 15px;
    }
    .btn-menu {
      width: 32px;
      height: 32px;
      &::before,
      &::after {
        width: 32px;
        box-shadow: 0 10px 0 #1f1f1f, 0 -10px 0 #1f1f1f;
      }
    }
    button,
    .account {
      display: inline-block;
    }
    button{
      margin: 0 10px;
    }
    .badge {
      top: -4px;
      right: 3px;
      font-size: 12px;
      line-height: 1.8;
      width: 21px;
      height: 21px;
    }
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

//  Tooltip menu
// ===================================================================

.tooltip-menu {
  opacity: 0;
  visibility: hidden;
  z-index: 50;
  position: absolute;
  right: -14px;
  min-width: 275px;
  margin-top: 8px;
  pointer-events: none;
  text-align: center;
  transform: translate(0, 8px);
  transition: opacity .3s ease-in .1s, transform .3s ease-in .1s, visible .3s;

  @include media-breakpoint-up(md) {
    right: -5px;
    margin-top: 0;
  }
  .product-number {
    display: block;
    padding: 25px 0 22px;
    margin: 0 15px;
    line-height: 1;
    font-size: 12px;
    color: #b1b1b1;
    border-bottom: 1px solid #eee;
  }
  .last-product {
    padding: 25px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
  }
  .title {
    display: block;
    margin-bottom: 20px;
    padding: 0 10px;
    font: italic 600 20px/1.1 $font2;
    color: $darkcolor4;
  }
  .title-product {
    display: block;
    margin-top: 15px;
  }
  .price-product {
    font-size: 13px;
    color: $color1;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate(0, 0);
  }
}

.tooltip-menu__wrapper {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(#000, .2);
  background: #fff;
  &::before {
    @include triangle(12px, #fff, top);
    content: '';
    position: absolute;
    top: -12px;
    right: 34px;
  }
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -4px;
    right: 33px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    box-shadow: 0 0 4px 0 rgba(#000, .2);
  }
  img {
    max-width: 155px;
    margin: auto;
  }
}

.tooltip-menu__actions {
  display: flex;
  a {
    padding: 15px;
    width: 50%;
    font-size: 14px;
    line-height: 1;
    border-bottom-left-radius: 8px;
    color: #fff;
    cursor: pointer;
    background: $darkcolor1;
    transition: .3s background;
    &:focus,
    &:hover {
      background: darken($darkcolor1, 5%);
    }
    &:last-child {
      background: $color1;
      border-radius: 0;
      border-bottom-right-radius: 8px;
      &:focus,
      &:hover {
        background: darken($color1, 5%);
      }
    }
  }
}

.tooltip-account-menu {
  text-align: left;
}

.tooltip-account__head {
  padding: 25px 25px 0;
  .username {
    font-size: 18px;
    font-family: $font2;
  }
}

.tooltip-account__links {
  padding: 15px 0 8px;
  margin: 5px 20px 0;
  li {
    font-size: 14px;
    line-height: 1;
    list-style: none;
    &:first-child,
    &.disconnect {
      border-top: 1px solid #eee;
    }
  }
  .disconnect {

  }
  a {
    display: inline-block;
    color: $color2;
    padding: 18px 3px;
    &:hover,
    &:focus {
      color: $color1;
    }
  }
}
