//  Main product
// ===================================================================

.hero-product .container,
.hero-product {
  min-height: 500px;
  @include media-breakpoint-up(md) {
    min-height: 646px;
  }
}

.hero-product {
  .container {
    position: relative;
  }
  .title {
    font-size: 31px;
    &:focus,
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
  .grid-product-info {
    z-index: 5;
  }
  @include media-breakpoint-up(md) {
    .title {
      font-size: 38px;
    }
    .size {
      font-size: 28px;
    }
  }
}

//  Grid product
// ===================================================================

.grid-main-product {
  min-height: 80vh;
  .container {
    padding: 0;
  }
  .row {
    flex-direction: column;
  }
  .col-md-6 {
    flex-basis: auto;
  }
  .bloc-link {
    content: "";
    padding: 40px 60px 40px 20px;
    &:after{
      content:"";
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      position: absolute;
      top: calc(50% - 1px);
      right: 30px;
    }
    .title {
      margin: 0;
      text-align: left;
      font-size: 28px;
      font-weight: 700;
    }
  }
  @include media-breakpoint-up(md) {
    position: relative;
    margin: -80px auto 80px;
    .col-md-6 {
      &:nth-child(even) {
        text-align: right;
        .bloc-grid-product {
          margin-left: 41px;
          margin-top: -279px;
          &.wide {
            margin-top: -279px;
            margin-left: 0;
          }
        }
      }
      &:nth-child(5),
      &:nth-child(7) {
        .bloc-grid-product {
          margin-top: -44px;
        }
      }
      &:nth-child(6) {
        .bloc-grid-product {
          &.wide {
            margin-top: -320px;
          }
        }
      }
      &:nth-child(8) {
        .bloc-link {
          margin-top: -315px;
        }
      }
    }
    .bloc-link {
      position: relative;
      max-width: 323px;
      height: 323px;
      margin-top: -160px;
      margin-left: 10px;
      text-align: center;
      padding: 60px 40px;
      &:after{
        top: auto;
        bottom: 30px;
        right: 30px;
      }
      .title {
        text-align: center;
        margin-bottom: 20px;
      }
      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%,-50%);
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    .container {
      max-width: 768px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -120px;
    .container {
      padding: 0 15px;
    }
    .col-md-6 {
      flex-basis: auto;
      &:nth-child(even) {
        .bloc-grid-product {
          margin-left: 131px;
          margin-top: -279px;
          &.wide {
            margin-top: -279px;
            margin-left: 0;
          }
        }
      }
      &:nth-child(5),
      &:nth-child(7) {
        .bloc-grid-product {
          margin-top: -35px;
        }
      }
      &:nth-child(6) {
        .bloc-grid-product {
          &.wide {
            margin-top: -410px;
          }
        }
      }
      &:nth-child(8) {
        .bloc-link {
          margin-top: -364px;
        }
      }
    }
    .bloc-link {
      max-width: 314px;
      height: 314px;
      padding: 60px 40px;
      margin-top: -77px;
      margin-left: 10px;

      .title {
        font-size: 40px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .col-md-6 {
      flex-basis: auto;
      &:nth-child(5),
      &:nth-child(7) {
        .bloc-grid-product {
          margin-top: -125px;
        }
      }
      &:nth-child(6) {
        .bloc-grid-product {
          &.wide {
            margin-top: -410px;
          }
        }
      }
      &:nth-child(8) {
        .bloc-link {
          margin-top: -274px;
        }
      }
    }
    .bloc-link {
      max-width: 424px;
      height: 424px;
      margin-top: -177px;
      margin-left: 10px;
      &:after{
        bottom: 40px;
        right: 40px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .col-md-6 {
      &:nth-child(even) {
        .grid-product-info {
          .title {
            max-width: 80%;
          }
        }
      }
    }
  }
}


//  Boxes banner
// ===================================================================
.hp-boxes-banner{
  height: 150px;
  background-color: #292929;
  margin-top: 120px;

  @include media-breakpoint-down(sm) {
    height: auto;
    margin-top: 50px;

  }

  &__wrapper{
    max-width: 830px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #fff;
    &:hover {
      color: #fff
    }
    height: 100%;

    @include media-breakpoint-down(sm) {
      padding: 25px 20px;
    }

  }

  &__figure{
    margin: 0;
    align-self: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      max-width: 300px;
    }

  }

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__sub-title{
    font-size: 20px;
    margin: 0 0 4px;
    color: #fff;
  }
  &__main-title{
    font-size: 32px;
    margin: 0;
    line-height: 1;
    letter-spacing: -1.5px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }

    svg{
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }
  }
  &__compatibility{
    display: flex;
    align-content: center;
    padding-top: 15px;
    p{
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
      letter-spacing: -.5px;
    }
    ul{
      display: flex;
      margin: 0;
      padding-left: 10px;

      li{
        &:not(:first-child){
          margin-left: 8px;
        }
      }
      svg{
        width: 30px;
        height: 30px;
      }
    }
  }
}
