//  Tooltip menu
// ===================================================================

.modal-dialog.cart-preview-wrapper {
  //z-index: 1050;
  //position: absolute;
  //right: -6px;
  max-width: 550px;
  //transform: translate(6px, 4px);
  //transition: opacity .3s ease-in .1s, transform .3s ease-in .1s, visible .3s;
  //box-shadow: 0 0 4px rgba(#000, .2);
  //background: #fff;
  //padding: 15px;
  //border-radius: 8px;
  //display: flex;
  //flex-direction: column;

  .footer {
    width: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }

  .cover {

    img {
      width: 100px;
      box-shadow: 5px 5px 12px 0 #ddd;
    }
  }

  .modal-body {
    display: flex;
    justify-content: center;

    .product-block {
      padding: 10px;
      display: flex;

      .product-information {
        padding-left: 20px;

        .product-name {
          font-weight: bold;
        }
      }
    }
  }

}


