/***********************
*		BLOG TYPES
************************/
.blogtypes {
	padding: 0;
	margin: 40px 0;

	li {
		margin-bottom: 40px;

		&:hover,
		&:focus {
			img {
				border-color: $color1;
			}
		}
	}

	img {
		display: block;
		margin: 0 auto;
		border-width: 3px;
		border-style: solid;
		border-radius: 50%;
		border-color: transparent;
		transition: border-color 0.5s ease;
	}

	span {
		display: block;
		margin-top: 10px;
		text-align: center;
	}
}


/*********************
*		BLOG FORM MODAL
**********************/
.blog-choice-logo {
	margin: 16px 0;
}

#platforms-modal-blog-book {
	.modal-body {
		margin-bottom: 0;
	}
}

.modal-form {
	label {
		font-size: 16px;
		margin: 0;
		min-width: 300px;
		text-align: left;

	    @include media-breakpoint-up(sm) {
			margin: 0 15px 0 0;
			min-width: 120px;
			text-align: right;
	    }

	}

	input {
		max-width: 300px;
		display: block;
		margin: 0 auto;

	    @include media-breakpoint-up(sm) {
	    	display: initial;
	    	margin: 0;
	    }
	 }

	.group {
		margin-bottom: 20px;
	}

	/*********************
	*		WORDPRESS IMPORT URL
	**********************/
	&.import-wordpress-url {
		div {
			max-width: 300px;
			position: relative;
			display: block;
			margin: 10px auto 40px auto;

			@include media-breakpoint-up(md) {
				margin: 0;
				min-width: 300px;
			}

			span {
				position: absolute;
				left: 0;
				font-size: 12px;
				color: $lightcolor6;
			}
		}

	}

	label {
		@include media-breakpoint-only(md) {
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
		}
	}

	button{
		margin: 0;
	}
}

.blog-choice-url-other-options {
	margin-top: 50px;

	p.separator {
		text-transform: uppercase;
		font-size: 12px;
		color: $lightcolor6;
	}

	a {
		display: block;
		font-size: 14px;
		line-height: 20px;
		margin-top: 30px;
	}
}
