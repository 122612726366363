.breadcrumb {
  margin: 60px 0 0;
  li {
    display: inline-block;
    position: relative;
    font-size: 12px;
    margin-right: 16px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 10px;
      top: 50%;
      right: -11px;
      background: #e0ddd6;
      transform: translate(0,-50%);
    }
    &:last-child {
      a {
        color: $color1;
        font-weight: 500;
      }
      &::after {
        display: none;
      }
    }
  }
  a,
  span {
    font-weight: 300;
    color: #7f7f7f;
  }
}
