// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
    overflow-x: hidden;
  overflow-y: auto;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include transition($modal-transition);
    transform: translate(0, -25%);
  }
  &.show {
    display: flex !important; //because F*** Bootstrap
    justify-content: center;
    align-content: center;
    padding: 30px;
    @include media-breakpoint-down(sm) {
      padding: 10px;
    }

    .modal-dialog {
      flex-grow: 1;
      transform: translate(0, 0);
    }
  }


  &.not-full {
    position: initial;
    background-color: lighten(#000, 50%);
    overflow: visible;
  }
}




// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  //margin: $modal-dialog-margin;
  margin: auto;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  @include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;

  &.modal-pol-conf {
    background-color: $color4;


    p {
      font-size: 14px;
    }
  }
  &.filter-modal {
    box-shadow: 0 0 4px 0 lighten(#000, 20%);

    .modal-header {
      text-align: left;
      padding-bottom: 0;
    }
    // transform: translateY(-50px);

    // &:before {
    //   @include triangle(18px, #fff, top);
    //   content: '';
    //   position: absolute;
    //   top: -18px;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  position: relative;
  padding: 40px 50px 15px;
  text-align: center;

  .close, .back {
    opacity: .6;
    position: absolute;
    padding: 15px;
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
    }

    &.modal-close {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .close {
    top: 5px;
    right: 5px;

    @include media-breakpoint-up(sm) {
      top: 10px;
      right: 10px;
    }

  }

  .back {
    top: 5px;
    left: 5px;

    @include media-breakpoint-up(sm) {
      top: 10px;
      left: 10px;
    }
  }

}

// Title text within header
.modal-title {
  display: block;
  margin: 0 auto;;
  text-align: center;
  color: $color2;
  font-size: 20px;
  font-family: $font1;
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  // line-height: $modal-title-line-height;
  line-height: 1.2;
  word-wrap: break-word;

  &.h1-like {
    color: $color1;

    small {
      text-align: center;
      padding: 0;
      margin: 0 0 6px;
      font-size: 24px;
    }
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: 20px;
  @include media-breakpoint-down(sm){
    padding: 10px;
  }
  margin-bottom: 50px;

  margin-bottom: 0;

  .content {
    font-size: 14px;
    font-weight: 300;
    color: $color2;
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 20px;

  // Easily place margin between footer elements
  > :not(:first-child) { margin-left: .25rem; }
  > :not(:last-child) { margin-right: .25rem; }
  &.stick-footer {
    z-index: 80;
    position: sticky;
    bottom: -30px;
    padding: 15px;
    box-shadow: 0 -3px 15px rgba(#7f7f7f, .2);
    background-color: #fff;
    .checkbox-button {
      label {
        margin-bottom: 0;
      }
    }
    .btn-link {
      padding: 10px;
      font-size: 11px;
      color: $color2;
      &:hover,
      &:focus {
        color: $color1;
      }
    }
  }




  .row {
    width: 100%;
  }
  .product-resume__price {
    border: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    margin: auto;
    padding: 0 auto;
  }
  @include media-breakpoint-up(md) {
    &.stick-footer {
      padding: 20px;
    }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: 790px;
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: $modal-sm; }
  .modal-md { max-width: $modal-md; }
}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}

.modal-theme {
  .modal-header {
    min-height: 500px;
    background-position: center 100%;
    background-size: 75% auto;
    background-repeat: no-repeat;
  }
  .modal-title {
    margin: 0 auto;
  }
  .modal-body {
    padding: 0;
  }
  @include media-breakpoint-up(md) {
    .modal-header {
      min-height: 600px;
      background-size: auto;
      background-position: center -100%;
    }
  }
}

.politique-conf {
  background-color: $color4;
  justify-content: center;

  a {
    color: $darkcolor4;
    text-align: center;
    font-weight: 400;
  }
}


.modal-account-action-switch{
  display: block;
  width: calc(100% + 70px);
  background: $color4;
  margin-left: -35px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: $darkcolor2;
  cursor: pointer;

  &:hover {
    color: $BlackMine;
  }
}

.modal-account-popin{
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-between(sm, lg){
    align-items: center;
  }

  @include media-breakpoint-up(lg){
    display: block;
  }

  @include media-breakpoint-only(xs){
    & + .modal-backdrop {
      opacity: .6;
    }
  }

  .modal-dialog{
    width: 500px;

    @include media-breakpoint-up(lg){
      transform: translateX(-240px) !important;
    }

    @include media-breakpoint-only(xs){
      padding: 0;
      margin: 0;
      width: 100%;

      .modal-unauthenticated-container{
        padding: 0;
      }

      .modal-body{
        padding-top: 25px;
      }

      .modal-header{
        position: absolute;
        bottom: 100%;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;

        small{
          color: white;
        }
      }

      .form-group{
        margin-bottom: 20px;
      }

    }

  }
}


.modal.modal-unauthenticated-popin{
  padding: 0;

  .modal-dialog{

    @include media-breakpoint-up(lg){
      transform: translateX(-240px) !important;
    }
  }

}

.modal-video-tutorial{
  .modal-dialog{
    width: 790px;
    .video-list-group{
      @include media-breakpoint-down(sm){
        flex-wrap: wrap;
      }
      justify-content: center;

      .video-list-group-item{
        @include media-breakpoint-down(sm){
          flex-basis: calc(1/5*100%);
        }
        @include media-breakpoint-up(md){
          flex-grow: 1;
        }
        background: $BeigeWood;
        font-size: 11px;
        font-weight: 500;
        display: block;
        color: $darkcolor1;
        text-transform: uppercase;
        border:1px solid white;
        padding: 13px 0;
        text-align: center;
        cursor: pointer;

        &:active{
          outline: none;
        }

        &.active{
          background: $RedCarnation;
          color: white;
          outline: none;
        }

      }
    }

    .video-description{
      border: 3px solid $BeigeWood;
      text-align: center;
      padding: 15px;
      font-size: 16px;
      color: $darkcolor2;
    }

    .video-actions{
      > *{
        margin: 0 10px;
      }
    }
  }
}


.modal-tomes{

  &-list{
    font-size: 14px;
    border: 3px solid #f5f2ed;
    max-width: 570px;
    padding: 30px 35px;
    margin: 0 auto;

    @include media-breakpoint-up(sm){
      padding: 30px 60px;
    }

    &.ol{
      padding-left: 45px;

      @include media-breakpoint-up(sm){
        padding-left: 70px;
      }

      li{
        padding-left: 8px;
      }

    }

    li{
      &:not(:first-child){
        margin-top: 20px;
      }
    }
  }
}
