.product-page {
  .product-resume__details {
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 0;
      li {
        float: left;
        margin: 15px 20px 0 0;
        &:nth-child(0n+3) {
          clear: left;
        }
      }
    }
  }
  .bloc-faq {
    padding: 70px 0 40px;
    @include media-breakpoint-up(md) {
      padding: 80px 0 90px;
    }
    @include media-breakpoint-up(lg) {
      padding: 110px 0;
    }
  }
}

//  Product infos
// ===================================================================

.product-content {
  position: relative;
  margin-top: 40px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.product-head {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 50%;
    width: 720px;
    transform: translate(-50%,0);
  }
  @include media-breakpoint-up(lg) {
    width: 960px;
  }
  @include media-breakpoint-up(xl) {
    width: 1140px;
  }
}

.product-resume {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product-resume__button {
  margin: 0 0 5px;

  @include media-breakpoint-only(sm) {
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    position: inherit;
    margin: 24px 0;
    flex-basis: 100%;
  }
  span{
        margin-top: 8px;
    background: none;
    padding: 0;
    text-transform: none;
    color:#7F7F7F;
    font-size:.8em;
  }
}

.product-resume__price {
  padding: 20px 15px;
  margin-right: 20px;
  letter-spacing: -1px;
  line-height: 1.1;
  font-size: 34px;
  text-align: left;
  font-weight: 300;
  color: $color2;
  border-top: 1px solid #e1ddd6;
  border-right: 1px solid #e1ddd6;
  @include media-breakpoint-up(md) {
    // margin-right: 0;
  }
  .from {
    display: block;
    color: $darkcolor1;
    font-weight: 400;
    font-size: 9px;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}

.product-resume__details {
  @include clearfix();
  padding: 0;
  margin: 0 0 40px;
  font-size: 12px;
  color: $darkcolor1;
  order: 1;
  li {
    margin: 15px 0;
    font-weight: 300;
    list-style: none;
  }
  img {
    width: 25px;
    margin-right: 15px;
  }
  @include media-breakpoint-up(md) {
    margin: 0;
    padding: 0;
    order: 0;
  }
}


//  Visuals & text
// ===================================================================

.main-visual-product {
  width: 100%;
  text-align: center;
  img {
    margin: auto;
  }
  @include media-breakpoint-up(md) {
    padding-top: 224px;
  }
}

.product-visuals {
  display: flex;
  max-width: 750px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  @include media-breakpoint-up(md) {
    margin-top: -215px;
  }
}

.product-visuals__item {
  figure {
    position: relative;
    max-width: 308px;
    margin: 0;
  }
  &:first-child {
    align-self: flex-end;
    figure {
      z-index: 5;
      max-width: 350px;
      margin-bottom: -30px;
    }
  }
  &:last-child {
    align-self: center;
    figure {
      margin-top: 25px;
      max-width: 300px;
    }
  }
  figcaption {
    position: absolute;
    bottom: 18px;
    left: 20px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 300;
    color: #fff;
    background: rgba(0,0,0,.6);
    border-radius: 4px;
    padding: 1px 5px;
  }
  @include media-breakpoint-up(sm) {
    &:first-child {
      figure {
        max-width: 423px;
      }
    }
    &:last-child {
      align-self: center;
      figure {
        margin-top: 25px;
        max-width: 300px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    &:first-child {
      figure {
        margin-bottom: -180px;
      }
    }
    &:last-child {
      figure {
        margin-top: -30px;
      }
    }
  }
}

.product-text {
  max-width: 470px;
  padding: 0 20px;
  margin: 50px auto;
  font-size: 16px;
  font-family: $font2;
  line-height: 1.8;
  color: $darkcolor1;
  @include media-breakpoint-up(md) {
    margin: 60px auto 120px;
  }
}


//  Bloc preview
// ===================================================================

.product-preview {
  padding: 40px 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  background: #35445b;
  perspective: 2000px;
  p {
    margin: 0 auto;
    max-width: 365px;
  }
  picture {
    display: inline-block;
    margin: 10px auto;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  .flipper {
    position: relative;
    height: 55vw;
    max-width: 940px;
    margin: 0 auto;
    transition: transform .8s cubic-bezier(0.23, 1, 0.32, 1);
    transform-style: preserve-3d;

    @include media-breakpoint-up(sm) {
      height: 300px;
    }
    @include media-breakpoint-up(md) {
      height: 400px;
    }
    @include media-breakpoint-up(lg) {
      height: 540px;
    }
    &.is-flipped{
      transform: rotateY(180deg);
    }
  }
  .flip {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    &.front {
      transform: rotateY(0deg);
      z-index: 2;
    }
    &.back {
      transform: rotateY(180deg);
    }
  }

  button{
    display:block;
  }
}
//  Bloc custom
// ===================================================================

.product-custom {
  padding: 35px 0 40px;
  text-align: center;
  background: $color4;
  .container {
    padding-right: 0;
    padding-left: 0;
    background: $color4;
   }
  .title-section {
    color: $color1;
    max-width: 450px;
    margin: auto;
    padding: 30px 0 80px;
  }
  @include media-breakpoint-up(md) {
    overflow: hidden;
    padding: 0;
    margin-top: 40px;
    background: transparent;
    .container {
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        width: 100%;
        background: $color4;
      }
    }
    .title-section {
      padding-top: 75px;
    }
  }
  @include media-breakpoint-between(sm, md) {
    .container {
      max-width: none;
      padding: 0;
    }
  }
  @include media-breakpoint-between(md, lg) {
    .container {
      padding: 0;
    }
  }
}

.product-custom__list {
  padding: 0;
  margin: 0;
  text-align: center;
  .title {
    display: block;
    font-size: 11px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $darkcolor2;
  }
  .item {
    display: inline-block;
    padding: 0 20px 50px;
    list-style: none;
    img {
      margin: auto;
      width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 0 0 60px;
  }
  @include media-breakpoint-up(lg) {
    .item {
      padding-bottom: 0;
      img {
        width: auto;
      }
    }
  }
}


.choices-item {
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin: 30px 0 0 0;
    padding-bottom: 130px;
  }

  .radio > label.selector-color {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
}
