//  Sections of the page
// ===================================================================

.list-product-page {
	position: relative;
	.bloc-link {
		max-width: 470px;
		margin-right: auto;
		margin-left: auto;
	}
}

.books-products {
	padding-bottom: 60px;
	.h1-like {
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 0;
	}
	.container {
		padding: 0;
	}
	.page-head .blogtypes{
		text-align:right;
		justify-content:flex-end;
		display:flex;
		li{
			text-align:center;
			&:not(:first-child) {
				margin-left: 30px;
			}
			a {
				cursor: auto;
			}
			span{
				display:block;;
				font-size:0.8em;
				color:#7F7F7F
			}
			img {
				max-width: 60px;
				border: none;
			}
		}
	}
	&:nth-of-type(even) {
		background-color: $color4;
	}
	@include media-breakpoint-up(sm) {
		&:nth-of-type(even) {
			.arrow-to-top {
				text-align: center;
				margin-top: 50px;
				img {
					max-width: 22px;
				}
			}
		}
	}
	@include media-breakpoint-up(md) {
		.h1-like {
			padding-top: 10px;
			padding-left: 0;
			padding-right: 0;
		}
		.container {
			padding-right: 15px;
			padding-left: 15px;
		}
		&:nth-of-type(even) {
			.h1-like {
			  text-align: right;
			  small {
				  padding-right: 0;
				  padding-left: 25%;
			  }
		  }
		}
	}
}

//  List of items
// ===================================================================

.list-products {
	max-width: 790px;
	margin: 10px auto 20px auto;
	padding: 0;
	list-style-type: none;
	.slick-slide {
		opacity: .5;
		transition: .3s opacity;
		&.slick-current {
			opacity: 1;
		}
	}
	@include media-breakpoint-up(md) {
		padding: 0 75px;
		// background: url("../svg/text-pattern.svg") no-repeat center 130px transparent;
		background-size: 680px auto;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 95px;
		background-size: 790px auto;
		background-position: center 150px;
	}
}

//  Two columns items
// ===================================================================
.list-products.two-columns {
	.col-sm-6 {
		&:nth-of-type(even) {
				margin-top: 0;
				&:last-of-type {
			    margin-bottom: 0;
		    }
		}
	}

	@include media-breakpoint-up(sm) {
		.col-sm-6 {
			&:nth-of-type(odd) {
				&:not(:first-of-type) {
				  margin-top: -50px;
			  }
			}

			&:nth-of-type(even) {
				margin-top: 100px;
			}
    }
  }
}



.list-products.one-columns {
	padding: 0 50px;
	@include media-breakpoint-up(sm) {
		padding: 0;
		.product-card {
			margin-bottom: 50px;

		}
	}
}


//  Bar list blog type
// ===================================================================
.list-blog-type {
	padding-left: 0;

	a {
		color: $darkcolor1;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 300;

		&.active {
			color: #fff;
		}
	}
}
