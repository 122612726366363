.container.list-orders {
	@include media-breakpoint-up(md) {
		max-width: 1140px;
	}

	.list-group-item {
		// padding: 12px 0;

		&:first-of-type, {
			border-top: none;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	.card-body {
		padding-bottom: 0;
	}

	.spaced {
		p{
			font-size: 14px;
			color: $darkcolor2;
			margin: 0;
			font-weight: 300;
		}
		span {
			font-size: 14px;
			max-width: 180px;
		}
	}

	.card {
		box-shadow: 2px 2px 4px 0px $shadowcolor;
	}

}


////////////////////////////////////////////
// informations de la commande
////////////////////////////////////////////
.order-infos {
	.spaced {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			span {
				text-align: right;
			}
		}
	}

	.list-group-item {
		padding: 12px 0;
	}
}

@include media-breakpoint-down(sm){
  .blookspace-cta {
    order: -1;
    margin-bottom: 40px;
  }
}

.blookspace-cta {
  width: 300px;
  color: white;
  text-align: center;
  background-color: $color1;
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;

  a {
    color: white;
    outline: none;
  }

  .square {
    padding-bottom: 100%;
    position: relative;
    & > div {
      padding: 10%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .arrow {
        text-align: right;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .title {
    font-weight: bold;
    font-size: 2rem;
  }
  .body {
    font-family: Playfair Display,Georgia,Times New Roman,Times,serif;
  }
}


////////////////////////////////////////////
// détail de la commande, (les produits)
////////////////////////////////////////////
.details-order {
	max-width: 80%;
	margin: 0 auto;

	&.show {
		display: flex;
		flex-direction: column;
	}
}

// produits infos
.details-order__infos {
	padding: 20px 0 0 0;
	max-width: 300px;
	margin: 0 auto;

	@include media-breakpoint-up(md) {
		max-width: 100%;
		// margin:
	}

	h5 {
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	.list-group {
		margin: 0 auto 30px auto;
	}

	.list-group-item {
		padding: 5px 0;
	}

	.spaced {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}


	.details-order__author {
		text-align: center;
		font-style: italic;
		color: $darkcolor1;
	}
}

.details-order__product {
	width: 100%;
	margin: 0;

	.details-order__img {
		max-width: 200px;
		margin: 0 auto;

		@include media-breakpoint-up(md) {
			max-width: 100%;
		}
	}
}

////////////////////////////////////////////
// Boutons d'actions
////////////////////////////////////////////
.order-actions {
	margin-bottom: 20px;
}

