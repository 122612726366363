form {
  .mandatory {
    font-size: 14px;
    font-weight: 400;
    color: $color-error;
  }
  .infos-form {
    font-size: 12px;
    font-weight: 300;
    color: $darkcolor1;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

input,
input[type="number"],
select,
textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Dirty fixes for auth and options forms
*/
input[type="radio"] {
  appearance: radio;
  -moz-appearance: radio;
  -webkit-appearance: radio;
}

#id_import_pages,
#id_import_comments {
  appearance: checkbox;
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox;
}

select {
  &::-ms-expand {
    display: none;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="phone"],
input[type="tel"],
select,
textarea,
.fake-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 3px 8px;
  font-family: $font1;
  font-size: 14px;
  line-height: 1.5;
  color: $darkcolor3;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-bottom: 1px solid $lightcolor7;
  border-radius: 0;
  box-shadow: none;
  transition: border-color ease-in-out .15s;
  &:disabled {
    color: #999;
    border-style: dashed;
    border-color: #c4bfb5;
  }
  &:hover,
  &:focus {
    border-color: darken($lightcolor7, 15%);
  }
  &.form-error {
    border-color: $color-error;
  }
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

input.plain-input,
.plain-input__parent > input{
  border: 1px solid $lightcolor7;
  padding: 15px 0 15px 19px;
  border-radius: 10px;

  &.inline {
    @include media-breakpoint-up(md) {
      border-top-left-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 0;
    }
  }

  &.no-radius {
    border-radius: 0;
  }
}

button.plain-input-btn {
  padding: 18px 20px;
  margin-left: -5px;

  @include media-breakpoint-up(md) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

textarea {
  border-radius: 3px;
  padding: 15px;
  margin-top: 9px;
  resize: none;
  border: 1px solid $lightcolor7;
}

input[type="file"] {
  display: block;
  width: 100%;
  padding: 8px 0 10px;
  cursor: pointer;
}

fieldset {
  legend {
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 600;
  }
}

.form-file {
  margin-top: 9px;
  cursor: pointer;
  label {
    display: block;
    position: relative;
    padding: 15px;
    border: 1px solid $lightcolor7;
    border-radius: 3px;
    font-size: 14px;
    font-family: $font1;
  }
  input {
    opacity: 0;
    position: absolute;
    width: .1px;
    height: .1px;
  }
  svg {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 22px;
    height: 22px;
    color: $color1;
    pointer-events: none;
    transform: translate(0,-50%);
  }
}

// Input type date
// --------------------------------------------------
.form-date {
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0
  }
  & > div {
    position: relative;
  }

  .input-date {
    margin: 0 10px 0 5px;
    padding: 10px;
    border-radius: 5px;
    outline: 0;

    &::-webkit-calendar-picker-indicator {
      background: $color1;
      position: relative;
      z-index: 2;
      opacity: 0;
    }

    &::-webkit-datetime-edit {
      text-decoration: none;
    }

    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appareance:none;
    }

    &:after {
      content: '';
      width: 30px;
      height: 30px;
      background: url("../svg/ICO_Calendar.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 20px;
    }
  }
}

.fake-label,
.form-label {
  display: block;
  line-height: 1;
  margin: 0 3px;
  font-weight: 400;
  font-size: 14px;
  color: $darkcolor1;
}

.form-group {
  margin-bottom: 40px;
  .group-inline {
    .fake-label {
      margin-bottom: 20px;
    }
    div {
      display: inline-block;
      margin: 0 5px;
    }
  }
  .row {
    & > div {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .help-text {
    font-size: 13px;
    font-weight: 300;
    color: $darkcolor1;
  }
  &.error {
    select,
    input {
      color: $color1;
      border-color: $color1;
    }
  }
  &.emphase {
    margin: 20px auto;
    padding: 25px;
    background: $color4;
    label {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    .row {
      & > div {
        margin-bottom: 0;
      }
    }
  }
}

.form-submit {
  .help {
    margin-top: 20px;
    font-size: 12px;
    color: $lightcolor1;
    text-decoration: underline;
  }
  @include media-breakpoint-down(sm) {
    .btn-primary {
      width: 100%;
      padding: 20px 25px;
    }
  }
}


// Select
// --------------------------------------------------

.form-select {
  position: relative;
  .form-control {
    padding-right: 1.5rem;
  }
  &::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    display: block;
    width: 15px;
    height: 11px;
    pointer-events: none;
    background: url("../svg/arrow-select.svg") no-repeat 0 0 transparent;
  }
}

// Radios
// --------------------------------------------------

.radio {
  position: relative;
  padding-left: 20px;
  margin-right: 15px;

  &.color {
    input[type="radio"] {
      display: none;
    }
    padding-left: 0px;
    margin-left: 0 !important;
    margin-right: 10px !important;
    text-align: center;
  }

  div.color-name{
    text-transform: capitalize;
    margin: 0;
    font-size: 0.75rem;
  }

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    font-family: $font1;
    color: $darkcolor3;
    padding-left: 15px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      margin-left: -20px;
      border: 2px solid $lightcolor1;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      transition: border 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 12px;
      height: 12px;
      left: 6px;
      top: 6px;
      cursor: pointer;
      margin-left: -20px;
      border-radius: 50%;
      transform: scale(0,0);
      transition: .1s cubic-bezier(.8,-0.33,.2,1.33);
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }

    &.selector-color {
      display: block;
      width: 72px;
      height: 72px;
      background: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: $CotonSeedGrey;
      cursor: pointer;
      position: relative;

      .secondary-color {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30%;
        margin: 0;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }

  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus {
      outline: 1px dotted $color3;
       & + label::before {
        // Default
        outline: thin dotted;
        // WebKit
        //outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }

    }

    &:checked + label::after {
      transform: scale(1,1);
      background-color: $color1;
    }


    &:checked + label.selector-color {
      outline: 4px solid $color1 !important;
      outline-offset: 2px;
    }

    &:disabled + label {
      opacity: 0.65;

      &::before,
      &.selector-color {
        cursor: not-allowed;
      }
    }



  }

  &.radio-inline {
    margin-top: 0;
  }
  &.color {
    label {
      &::before {
        width: 38px;
        height: 38px;
        border: 9px solid $color1;
        background-color: $color1;
      }
    }
    input {
      &:checked + label::before {
        background-color: #fff;
      }
      &:checked + label::after {
        background-color: transparent;
      }
    }
  }
  @include media-breakpoint-up(md) {
    label {
      font-size: 16px;
    }
  }
}


// Checkbox
// --------------------------------------------------

.checkbox {
  position: relative;
  margin-right: 15px;
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    font-family: $font1;
    font-size: 14px;
    color: $darkcolor3;
    text-transform: none;
    letter-spacing: 0;
    position: relative;
    padding-left: 15px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      margin-left: -20px;
      border: 2px solid $lightcolor1;
      border-radius: 3px;
      cursor: pointer;
      background-color: transparent;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 3px;
      left: -20px;
      font-size: 15px;
      line-height: 16px;
      cursor: pointer;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus {
      outline: 1px dotted $color3;
      & + label::before {
        // Default
        outline: thin dotted;
        // WebKit
        //outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }

    &:checked + label::after {
      content: '';
      display: inline-block;
      text-align: center;
      background: url("../svg/icon-checkbox.svg") no-repeat center center transparent;
      background-size: 12px 11px;
      width: 24px;

    }

    &:disabled + label {
      opacity: 0.65;

      &::before{
        background-color: $lightcolor1;
        cursor: not-allowed;
      }
    }

  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    label {
      font-size: 16px;
    }
  }
}

// Checkbox button
// --------------------------------------------------

.checkbox-button {
  display: inline-block;
  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }
  label {
    position: relative;
    display: inline-block;
    color: #aaa;
    //line-height: 28px;
    font-family: $font1;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0;
    padding: 8px 10px 6px 30px;
    margin: 0 2px 6px 0;
    //height: 32px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid $color4;
    background-color: $color4;
    svg {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 12px;
      height: 11px;
      transform: translate(0,-50%);
      pointer-events: none;
    }
  }
  input:checked + label {
    color: #fff;
    border-color: $color1;
    background: $color1;
  }
  @include media-breakpoint-up(md) {
    label {
      font-size: 11px;
      padding: 8px 14px 6px 35px;
      svg {
        left: 14px;
      }
    }
  }


  &.all-publications {
    label {
      padding-left: 14px;
    }
  }
}


// Switch
// --------------------------------------------------

.switch-button {
  position: relative;
  display: inline-block;
  input {
    position: absolute;
    left: 0;
    top: -2px;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    &:checked + label {
      &::before {
        left: 25px;
        background: $color1;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    border: 1px solid $color3;
    background: $color3;
    border-radius: 60px;
    width: 46px;
    height: 26px;
    box-sizing: unset;
  }
  label {
    padding-left: 65px;
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      display: block;
      box-sizing: border-box;
      border-radius: 50%;
      z-index: 0;
      position: absolute;
      top: 7px;
      left: 7px;
      background-color: #b5b5b4;
      transition: .3s all;
    }
  }
}


.btn-search {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 10px -3px $lightcolor2;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    background: url('../svg/ico-search.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}


// grey legend form
// --------------------------------------------------
.grey {
  color: $darkcolor1;
}



// Range
// --------------------------------------------------

.range{
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .range__min,
  .range__max {
    margin: 0;
  }

  input[type="range"] {
    appareance:none;
    -webkit-appearance: none;
    width: 250px;
    background: transparent;

    &:focus,
    &:hover {
      outline:0;
    }
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 3px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }

  input[type=range]::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $color1;
    margin-top: -6px;
  }
}

.range-value {
  font-weight: 600;
  color: $color1;
}










.datepicker{
    border-radius: 3px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: .875em;
    line-height: 1.71429em;
    border: 1px solid #aaa;
    color: #333;
    padding: 5px 10px 3px;
    margin: 0;
    display: block;

  }

.color-category-name {
  color: $CotonSeedGrey;
  font-size: 1.2rem;
  font-style: italic;
  text-transform: capitalize;
  margin-bottom: 0.4rem;
}

@include media-breakpoint-only(xs) {
  .color-category {
    div.radio {
      margin-bottom: 5px;
    }
    max-width: 250px;
  }
}
