.boxes {

  &-intro{
    color: $GrayGrey;
    margin-bottom: 4rem;
  }

  &-code{
    background-color: $BeigeWood;
    @include media-breakpoint-up(lg){
      background-image:url('../images/boxes/mockup_card.png');
      background-repeat: no-repeat;
      background-position: 30% 50%;
    }
  }

  &-code{
    display: flex;
    align-items: center;
    padding: 30px 20px;

    @include media-breakpoint-up(lg){
      height: 500px;
      padding: 0;
    }

    p{
      font-size: 14px;

      @include media-breakpoint-up(sm){
        font-size: 16px;
      }
    }

    &-container{
      margin: 0 auto;
      position: relative;

      @include media-breakpoint-up(sm){
        padding: 30px 35px;
        width: 450px;
        border: 2px solid $RedCarnation;
        border-radius: 20px;
      }

      @include media-breakpoint-up(lg){
        transform: translateX(50%);
        padding: 35px;
      }

      &.success, &.fail{
        border: none;

        &:after{
          content:"";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          display: block;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
          animation: codeIcon 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
        .blookup-logo{
          display: none;
        }
      }

      &.success{
        p{
          display: none;
        }
        &:after{
          width: 450px;
          height: 350px;
          background-image: url('../svg/inline-checkmark.svg');
        }
      }
      &.fail{
        .boxes-code-inputs{
          margin-bottom: 1rem;
        }
        p{
          display: none;
        }
        .fail-message{
          background: white;
          padding: 15px;
          font-size: 14px;
          color: $color1;

          ul{
            padding: 0;
            margin: 0;
          }
        }
        &:after{
          @include media-breakpoint-up(lg){
            width: 350px;
            height: 350px;
            background-image: url('../svg/inline-cross.svg');
          }
        }
      }
    }
    .blookup-logo{
      width: 105px;
      height: 28px;
      color: $TeaBeige;
      display: flex;
      flex-direction: column;
      margin: 0 auto 2rem;

      @include media-breakpoint-up(lg){
        margin-bottom: 3rem;
      }
    }
    &-inputs{
      display: flex;
      justify-content: center;
      align-items: center;
      .sep{
        font-size: 16px;
        margin: 0 5px;
        color: $darkcolor1;
      }
    }
    .code{
      background: white;
      text-transform: uppercase;
      border: none;
      padding: .75rem .5rem;
      font-size: 17px;
      height: 51px;
      flex: 1;
      min-width: 0; //sinon ça dépasse sur firefox
      text-align: center;
      color: $RedCarnation;
    }
    .submit{
      border: none;
      background: $TeaBeige;
      width: 0px;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;
      opacity: 0;
      transition: opacity .3s ease-out, width .3s ease-out;

      &.done{
        opacity: 1;
        width: 51px;
      }

      &:hover{
        background: $RedCarnation;
      }
      svg{
        width: 20px;
        height: 15px;
        color: white;
      }
    }
    p{
      strong{
        color: $RedCarnation;
        display: block;
      }
    }
  }

  &-main-cta{
    background: $RedCarnation;
    padding: 20px 0;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md){
      padding: 30px 0;
      margin-bottom: 5rem;
    }


    &-title{
      color: white;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(md){
        font-size: 28px;
      }
      @include media-breakpoint-up(lg){
        font-size: 32px;
      }
    }

    a{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg{
      fill: white;
      height: 80px;
      width: 80px;
      color: white;
    }
  }

  &-stores{
    &-title.h1-like{
      @include media-breakpoint-up(md){
        font-size: 32px;
      }
    }
    &-list{
      @include media-breakpoint-up(md){
        margin-bottom: 5rem;
      }

      img{
        max-width: 160px;
        margin: auto 0;
        &.mollat{
          max-width: 100px;
        }
        &.fnac{
          max-width: 90px;
        }
        &.cdiscount{
          max-width: 190px;
        }
      }
      a{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @include media-breakpoint-up(md){
          height: 140px;
        }

      }
      span{
        display: block;
        text-decoration: underline;
        padding-top: 10px;
        font-weight: 600;
      }
    }
  }

  &-bookshop-list{
    a{
      font-weight: 600;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 4px;
    }
    address{
      color: $darkcolor1;
    }
  }

  &-blook-list{
    position: relative;
    overflow: hidden;

    &.list-3 .boxes-blook{

      &:nth-child(3n+1){
        .boxes-blook-informations{
          &:before{
             @include media-breakpoint-up(sm){
               left: 25%;
            }
             @include media-breakpoint-up(xl){
               left: 15%;
            }
          }
        }
      }

      &:nth-child(3n+2){
        .boxes-blook-informations{
          @include media-breakpoint-up(md){
            margin-left: calc(-100% - 30px);
          }
          &:before{
            @include media-breakpoint-up(sm){
              left: 75%;
            }
            @include media-breakpoint-up(xl){
              left: 50%;
            }
          }
        }
      }

      &:nth-child(3n+3){
        .boxes-blook-informations{
          @include media-breakpoint-up(xl){
            margin-left: calc(-200% - 60px);
          }
          &:before{
            @include media-breakpoint-up(sm){
              left: 25%;
            }
            @include media-breakpoint-up(xl){
              left: 85%;
            }
          }
        }
      }
    }

    &.list-2 .boxes-blook{

      &:nth-child(odd){
        .boxes-blook-informations{
          &:before{
            left: 25%;
          }
        }
      }

      &:nth-child(even){
        .boxes-blook-informations{
          @include media-breakpoint-up(md){
            margin-left: calc(-100% - 30px);
          }
          &:before{
            left: 75%;
          }
        }
      }
    }

    .boxes-blook{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-details{
        display: flex;
        height: 200px;
        background: red;
        width: 1140px;
        left: 0;
        position: absolute;
      }

      &-img{
        padding-bottom: 20px;
        padding-top: 30px;
        display: flex;
        align-items: flex-end;
        position: relative;
        width: 100%;



        img{
          @include media-breakpoint-only(xs){
            margin-left: auto;
            margin-right: auto;
          }
        }

        @include media-breakpoint-up(sm){
          height: 460px;

          &.boxes-index-img{
            height: auto;
          }
        }

        @include media-breakpoint-up(xl){
          height: 500px;
          padding-bottom: 35px;
        }

        &:after{
          content: "";
          height: 100%;
          background: $BeigeWood;
          top: 0;
          width: 200vw;
          position: absolute;
          display: block;
          z-index: -1;
          left: -100vw;
        }
      }

      &-desc{
        color: $darkcolor1;
        font-family: $playfair;
        font-style: italic;
        font-size: 20px;

        @include media-breakpoint-up(lg){
          font-size: 22px;
        }

        @include media-breakpoint-only(lg){
          padding-right: 100px;
        }

        @include media-breakpoint-up(lg){
          padding-right: 160px;
        }
      }

      &-compatibility{
        &-label{
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
        }
        &-list{
          display: flex;
          padding: 0;
          svg{
            color: $RedCarnation;
            width: 35px;
            height: 35px;
          }
          li:not(:first-child){
            margin-left: 15px;
          }
        }
      }

      &-reas{
        padding: 0;
        li{
          display: flex;
          align-items: center;
          font-size: 16px;
          color: $darkcolor1;
          margin-bottom: 20px;

          @include media-breakpoint-up(lg){
            font-size: 18px;
          }

        }
        svg{
          color: $RedCarnation;
          width: 28px;
          height: 22px;
          margin-right: 12px;

          @include media-breakpoint-up(lg){
            width: 32px;
            height: 26px;
          }
        }
      }

      &-page-number{
        color: $GrayGrey;
        display: flex;
        align-items: center;
        svg{
          fill: currentColor;
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
      }

      &-specs{
        padding: 0;
        li{
          color: $GrayGrey;
          font-size: 12px;
          &:not(:first-child){
            margin-top: 10px;
          }
          img{
            width: 25px;
            margin-right: 12px;
          }
        }
      }

      &-informations{
        border-width: 4px;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md){
          width: calc(720px - 30px);
        }

        @include media-breakpoint-up(lg){
          width: calc(960px - 30px);
          display: flex;
        }

        @include media-breakpoint-up(xl){
          width: calc(1140px - 30px);
        }

        &:before{
          @include media-breakpoint-up(md){
            position: absolute;
            content: "";
            bottom: 100%;
            width: 0;
            height: 0;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $BeigeWood transparent;
          }
        }

        > *:not(a) {
          @include media-breakpoint-up(lg){
            width: 50%;
          }
        }

        &-text{
          padding: 10px 30px;
          @include media-breakpoint-up(md){
            padding: 20px 35px;
          }
        }

        &-title{
          font-size: 24px;
          @include media-breakpoint-up(md){
            font-size: 28px;
          }
        }

        &-p{
          line-height: 2;
          font-family: $playfair;
          color: $darkcolor4;

          @include media-breakpoint-up(md){
            font-size: 18px;
          }
        }

        &-gallery{
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;

          li{
            width: 50%;
          }
        }

        &-close{
          background: $BeigeWood;
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;

          svg{
            width: 16px;
            height: 16px;
          }
        }

      }
    }
  }
}


@keyframes codeIcon{
  from{
    transform: translate(-50%, -50%) scale(.8);
  }
  to{
    transform: translate(-50%, -50%) scale(1);
  }
}
