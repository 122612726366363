.paginate-preview {
  z-index: 1050;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 86px;
  width: 275px;
  background: $color3;

  @include media-breakpoint-down(sm){
    left: 0;
    width: 185px;
  }

  &.b2b-paginate{
    left: 0;
    transform: translateX(-275px);
    transition: transform .6s cubic-bezier(0.23, 1, 0.32, 1);

    &.is-visible{
      transform: translateX(0);
    }
  }


}

.paginate-preview__list {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0 36px 80px;
  display: flex;
  flex-wrap: wrap;

  &.wider {
    .page-item {
      &:first-child {
        margin-left: 0;
      }
      display: block;
      width: auto;
    }
    li {
      &.back-cover .page-item,
      &.cover-page .page-item{
        width: auto;
      }
    }
  }


  li {
    &:not(.cover-page){
      display: flex;
    }
    list-style: none;
    margin: 30px 0;
    &.back-cover,
    &.cover-page {
      .page-item {
        width: 50%;
      }
    }
    &.cover-page {
      text-align: right;
      .page-item {
        margin-right: 0;
      }
    }
    &.back-cover {
      text-align: left;
    }
    // &.active {
    //   a {
    //     box-shadow: 0 0 0 2px $color2;
    //   }
    // }
  }
  a {
    position: relative;
    display: flex;
    font-size: 0;
    &:focus,
    &:hover {
      img {
        box-shadow: 3px 3px 15px rgba(#000,.25);
      }
    }
  }
  .page-item {
    margin-top: 15px;
    margin-right: 1px;
    cursor: pointer;
    display: block;
    text-align: center;
    height: auto;
    img {
      transition: .3s box-shadow;
      box-shadow: 3px 3px 15px rgba(#000,.15);
    }
    @include media-breakpoint-up(md){
      width: 49%;
      &:first-child {
        margin-left: 49%;
      }
    }
  }
  .page-number {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #c4bfb5;
  }
}

.paginate-preview__goto {
  position: absolute;
  height: 70px;
  padding: 18px 25px;
  right: 0;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  background: $color1;

  .form-group {
    display: flex;
    align-items: baseline;
  }
  label,
  input,
  button {
    width: auto;
    font-size: 12px;
  }
  label {
    font-weight: 300;
    color: #fff;
    margin-bottom: 0;
    margin-right: 5px;
  }
  button {
    padding: 8px 15px;
    margin-left: 10px;
    color: #fff;
    border-color: #fff;
  }
  input {
    text-align: center;
    color: #fff;
    padding: 4px 8px;
    max-width: 40px;
    margin: 0 5px 0 0;

    &, &:hover{
      border-color: transparent;
      border-bottom-color: white;
    }
    &:focus{
      border: 1px solid white;
      outline: none;
    }
  }
}
