.cookie-banner{
	background: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: block;
	z-index: 1000;
	font-size: 11px;
	padding: 10px 0;
	box-shadow: 0 10px 40px rgba(0,0,0,.1);

	@include media-breakpoint-up(md){
		padding: 6px 0;
	}

	.btn-banner{
		padding: 7px 10px;
	}
}