//  Title
// ===================================================================

h1,
.h1-like {
  margin-bottom: 26px;
  font-size: 28px;
  font-family: $font1;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -1px;
  color: $color1;

  &.modal-title {
    font-size: 28px;
  }

  small {
    display: block;
    margin: 5px 0;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 400;
    font-style: italic;
    font-family: $font2;
    color: $darkcolor2;
    &.subhead {
      font-size: 20px;
    }
    a {
      color: $color1;
      text-decoration: underline;
    }
  }
  @include media-breakpoint-up(md) {
    font-size: 40px;
    small {
      font-size: 23px;
      &.subhead {
        font-size: 28px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    small {
      padding-right: 25%;
    }
  }
}

h2,
.h2-like {
  padding-top: 50px;
  margin-bottom: 20px;
  font-family: $font1;
  font-size: 24px;
  font-weight: 600;
  color: $color2;
}

h3,
.h3-like {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: $font2;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  color: $darkcolor3;
  &.subline {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color4;
  }
}

h4,
.h4-like {
  margin-top: 30px;
  font-family: $font2;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: $color2;
}

h5 {
  text-transform: uppercase;
  font-family: $font1;
  font-size: 16px;
  font-weight: 900;
}


a,
button {
  &:focus {
    outline: 3px solid rgba(#c1e0fe, .5);
    outline-offset: 1px;
  }
}

a {
  color: $color1;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($color1, 8%);
  }

  &.underline {
    text-decoration: underline;
    font-size: 14px;
  }
}

//  Definition
// ===================================================================

.dl-horizontal {
  @media screen and (max-width: 991px) and (orientation: landscape) {
    dt {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    dd {
      margin-left: 180px;
    }
  }
}


//  specifique font-weight: 
// ===================================================================

.f-600 {
  font-weight: 600;
}


//  specifique font-size: 
// ===================================================================
.fs-14{
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}