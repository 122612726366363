//  Tooltip menu
// ===================================================================

.tooltip-menu {
  opacity: 0;
  visibility: hidden;
  z-index: 50;
  position: absolute;
  right: -14px;
  min-width: 275px;
  margin-top: 8px;
  pointer-events: none;
  text-align: center;
  transform: translate(0, 8px);
  transition: opacity .3s ease-in .1s, transform .3s ease-in .1s, visible .3s;

  @include media-breakpoint-up(md) {
    right: -5px;
    margin-top: 0;
  }
  .product-number {
    display: block;
    padding: 25px 0 22px;
    margin: 0 15px;
    line-height: 1;
    font-size: 12px;
    color: #b1b1b1;
    border-bottom: 1px solid #eee;
  }
  .last-product {
    padding: 25px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
  }
  .title {
    display: block;
    margin-bottom: 20px;
    padding: 0 10px;
    font: italic 600 20px/1.1 $font2;
    color: $darkcolor4;
  }
  .title-product {
    display: block;
    margin-top: 15px;
  }
  .price-product {
    font-size: 13px;
    color: $color1;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate(0, 0);
  }
}

.tooltip-menu__actions {
  display: flex;
  a {
    padding: 15px;
    width: 50%;
    font-size: 14px;
    line-height: 1;
    border-bottom-left-radius: 8px;
    color: #fff;
    cursor: pointer;
    background: $darkcolor1;
    transition: .3s background;
    &:focus,
    &:hover {
      background: darken($darkcolor1, 5%);
    }
    &:last-child {
      background: $color1;
      border-radius: 0;
      border-bottom-right-radius: 8px;
      &:focus,
      &:hover {
        background: darken($color1, 5%);
      }
    }
  }
}

.tooltip-account-menu {
  text-align: left;
}

.tooltip-account__head {
  padding: 25px 25px 0;
  .username {
    font-size: 18px;
    font-family: $font2;
  }
}

.tooltip-account__links {
  padding: 15px 0 8px;
  margin: 5px 20px 0;
  li {
    font-size: 14px;
    line-height: 1;
    list-style: none;
    &:first-child,
    &.disconnect {
      border-top: 1px solid #eee;
    }
  }
  .disconnect {

  }
  a {
    display: inline-block;
    color: $color2;
    padding: 18px 3px;
    &:hover,
    &:focus {
      color: $color1;
    }
  }
}
