.bloc-presentation {
  margin: 50px auto 70px;
  .title-section {
    color: $color1;
    margin-bottom: 30px;
  }
  .slick-dots {
    margin-top: 20px;
    text-align: center;
    li {
      button {
        &::before {
          border-color: #dcdcdc;
        }
      }
      &.slick-active {
        button {
          &::before {
            border-color: $color1;
            background: $color1;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 140px;
    .slick-dots {
      position: relative;
      text-align: center;
      max-width: 940px;
      margin: 30px auto 40px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: #dcdcdc;
      }
      li {
        margin: 0 30px;
        button {
          font-size: 11px;
          padding-top: 20px;
          text-transform: uppercase;
          color: #9b9b9b;
          &::before {
            z-index: 5;
            position: absolute;
            top: 0;
            left: 50%;
            height: 14px;
            width: 14px;
            margin: auto;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .slick-dots {
      li {
        margin: 0 60px;
      }
    }
  }
}

.bloc-presentation__list {
  padding: 0;
  margin: 30px auto;
  display: block;
  img {
    margin: auto;
  }
  .item {
    //min-height: 370px;
    //max-width: 940px;
    margin: 30px 8px;
    padding: 0 20px;
    list-style: none;
    box-shadow: 0 0 0 1px $color3;
  }
  .content {
    padding: 20px 0 40px;
  }
  .text {
    position: relative;
    padding-left: 65px;
    max-width: 260px;
    margin: 15px auto 0;
    font-size: 14px;
    font-weight: 500;
    color: $darkcolor1;
  }
  .step {
    display: block;
    position: absolute;
    top: 50%;
    left: 8px;
    height: 70px;
    line-height: 70px;
    font-size: 70px;
    font-weight: 300;
    color: $darkcolor1;
    transform: translate(0,-50%);
  }
  .slick-current {
    box-shadow: 0 0 0 3px $color1;
    .content {
      margin-top: -1px;
    }
  }
  @include media-breakpoint-up(sm) {
    .content {
      padding: 30px 20px 40px;
    }
    .item {
      margin: 30px 10px;
    }
    .slick-current {
      box-shadow: 0 0 0 5px $color1;
      .content {
        margin-top: 8px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .content {
      padding: 40px 20px 55px;
    }
    .item {
      margin: 30px 11px;
      min-height: 480px;
    }
    img {
      max-width: 510px;
    }
    .text {
      max-width: 450px;
      padding-left: 70px;
      font-size: 22px;
    }
    .step {
      height: 90px;
      line-height: 90px;
      font-size: 90px;
    }
    .slick-current {
      margin: 15px 11px;
      box-shadow: 0 0 0 8px $color1;
    }
  }
  @include media-breakpoint-up(xl) {
    max-width: 1210px;
  }
}
