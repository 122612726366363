
@mixin active-button {
  color: $lightcolor2;
  cursor: initial;
  &:hover,
  &:focus {
    outline: 0;
    color: $lightcolor2;
  }
}

@include media-breakpoint-up(md) {
  .default-view #btn-grid {
      @include active-button;
  }
}

@include media-breakpoint-down(sm) {
  .default-view #btn-list {
      @include active-button;
  }
}

.list-view .group-button > #btn-list {
      @include active-button;
}
.grid-view .group-button > #btn-grid {
      @include active-button;
}

.group-button {
  & > .btn {
    display: inline-block;
    position: relative;
    padding: 12px 10px;
    margin: 0;
    color: $darkcolor4;
    transition: color .4s;
    &:hover,
    &:focus {
      color: $color1;
    }
    &.active {
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 15px;
      top: 50%;
      right: 0;
      background: #e0ddd6;
      transform: translate(0,-50%);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
