/* XXX why do we have one definition here and another in account.scss ??? */
.product-card {
    margin-bottom: 30px;
    overflow: hidden; /* avoids long titles getting out of the card box */
    .btn-border {
        opacity: .4;
        font-size: 11px;
        padding: 10px 14px;
        color: $color2;
        border: 1px solid $color2;
        transition: .3s opacity;
        &:focus,
        &:hover {
            text-decoration: none;
            opacity: .7;
        }
    }
  @include media-breakpoint-up(md) {
      max-width: 230px;
  }
  .button-block {
      width: 100%;
      display: flex;
      flex-direction: column;
  }
}


.product-card__price {
    display: block;
    text-align: left;
    margin-left: 20px;

    span {
        display: block;
        text-transform: uppercase;
        font-size: 10px;
        color: $darkcolor2;
        &.price {
            font-size: 20px;
            display: inline-block;
            padding: 0 10px;
            color: #fff;
            background-color: $color2;
        }
    }
}

.product-card__img {
    width: 100%;
}

.product-card__title {
    margin-top: 15px;
    margin-bottom: 5px;
    font-family: $font1;
    font-style: normal;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -1px;
    color: $color2;
    /*
    @include media-breakpoint-up(md) {
        font-size: 26px;
    }
   */
    word-wrap: break-word;
}

.product-card__subtitle {
    font-family: Montserrat,Helvetica Neue,Helvetica,Arial,Nimbus Sans L,sans-serif;
    color: #757575;
    font-style: normal;
    word-wrap: break-word;
}

.product-card__product {
    font-family: Playfair Display,Georgia,Times New Roman,Times,serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    color: #3c3c3c;
}


//  Listing view
// ===================================================================

@mixin list-view {
  .button-block {
    height: 100%;
    //position: absolute;
    //right: 0;
    width: 50%;
    flex-grow: 1;
    & > .btn,
    & > form > .btn {
      max-width: 250px;
    }
  }
    li.blook-detail-wrapper {
        clear: both;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e0ddd6;
    }
    .btn {
        margin-right: 10px;
    }
    .btn-primary {
        font-size: 11px;
        padding: 10px 14px;
    }
    .product-card {
        max-width: none;
        flex-direction: row !important;
    }
    .product-card__title {
        font-size: 20px;
        margin-top: 7px;
    }
    .product-card > .product-card__img {
        width: 100px;
        margin-right: 18px;
        flex-grow: 1;
        max-width: 250px;
    }
    .product-card__infos {
        margin-left: 25px;
        overflow: hidden;
    }
}

.list-view {
  @include list-view;
}
@include media-breakpoint-down(sm) {
  .default-view {
    @include list-view;
  }
}
