.purchase {

  .main-content,
  .site-header,
  .site-footer {
    max-width: 780px;
    margin: auto;
  }

  //  Header
  // ===================================================================

  .site-header {
    .site-header__group-buttons {
      color: #525151;
    }
  }

  .payment-ribbon {
    text-align: center;
    padding: 14px 0;
    font-size: 12px;
    color: #515356;
    background: $color3;
    .picto {
      max-width: 16px;
      margin: -4px 7px 0 0;
    }
    .img-fluid {
      width: 210px;
      margin-top: .75rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 18px;
      }
    }
  }


  .form-address {
    .h2-like {
      margin-bottom: 10px;
    }
    .infos-form {
      margin-bottom: 40px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
      }
    }
    .form-address-inner {
      padding: 0 10px;
      @include media-breakpoint-up(lg) {
        padding: 0 20px;
      }
    }
  }

  //  Footer
  // ===================================================================

  .site-footer {
    padding-top: 30px;
    background: transparent;
    .item-reassurance {
      padding: 0;
      margin: 0;
      li {
        position: relative;
        display: inline-block;
        margin: 15px 0;
        padding-left: 35px;
        font-size: 14px;
        font-weight: 300;
        color: #525151;
        &:last-child {
          display: block;
        }
      }
      span {
        display: block;
      }
      img {
        position: absolute;
        top: -1px;
        left: 0;
        max-width: 24px;
      }
    }
    @include media-breakpoint-up(sm) {
      .item-reassurance {
        li {
          margin-right: 25px;
        }
      }
    }
  }
  .site-footer__colophon {
    margin-top: 45px;
    padding-top: 40px;
    border-top: 2px solid #eee;
    .copyright {
      opacity: 1;
    }
    .form-select {
      opacity: 1;
    }
    @include media-breakpoint-up(sm) {
      padding-top: 25px;
    }
  }
}
