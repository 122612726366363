.account-header {
  text-align: center;
  margin-bottom: 60px;
}

.account {
  .breadcrumb {
    margin-top: 15px;
  }

  .form-profil {
    max-width: 690px;
    margin: auto;
  }

  .main-content {
    padding-top: 60px;
    padding-bottom: 0;
  }

  .modal-header {
    padding-top: 24px;
    @include media-breakpoint-down(sm) {
      padding-top: 14px;
    }
    padding-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    .modal-dialog {
      max-width: 640px;
    }
  }
}

.new-blook {
  .btn {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  img {
    width: 100%;
  }
}

//  User info
// ===================================================================

.user-resume {
  overflow: hidden;
  clear: both;
  max-width: 500px;
  margin: 40px 0 80px;
  img {
    float: left;
    margin-right: 15px;
  }
}

.user-resume__info {
  margin: 8px auto 20px;
  overflow: hidden;
  span {
    font-family: $font1;
    font-size: 20px;
    font-weight: 500;
  }
  p {
    font-family: $font2;
    color: $darkcolor1;
  }
}

//  Blook list
// ===================================================================

.account-last-blook {
  background: $color4;
  padding: 40px 0;
  .container {
    position: relative;
  }
  .title {
    margin-top: 0;
    margin-bottom: 35px;
    padding-top: 0;
    font-family: $font1;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 25px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

  }
  .group-button {
    width: 100%;
    display: flex;
    align-content: end;
    border-bottom: 1px solid rgba(#e0ddd6, .6);
    justify-content: end;
    align-items: center;
    margin-bottom: 13px;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      .filters {
        &::after {
          display: none;
        }
        .btn {
          margin-left: 2px;
          margin-right: 2px;
        }
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
      }

    }
    .filters {
      .label {
        margin-right: 5px;
      }
    }
    .btn-filter {
      background: #e2ded4;
      border-radius: 10px 10px;
      margin: 0;
      padding: 10px;
      color: #242424;

      &.active, &:hover {
        color: #fff;
        background-color: $color1;
      }
    }
  }
  .product-card {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .button-block {
      & > .btn,
      & > form > .btn {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    h4 {
      margin-top: 0;
    }
    .product-card__img {
        /* margin-top: auto; // doesn't work well with long titles */
        margin-bottom: auto;
    }
    .product-card__title-block {
        min-height: 136.9px;
    }
    .btn-publish {
        background-color: #f7f7f7;
        color: #4a4a4a;
        border: 1px solid #ccc;
    }
  }
  .publish-modal {
    .modal-dialog {
        max-width: 550px;
    }
    .modal-body {
      .publish-img {
          margin-right: 20px;
          flex-shrink: 1.2;
          img {
              max-width: 100%
          }
      }
      display: flex;
    }
    .footer {
        width: 100%;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
    }
    @include media-breakpoint-down(sm) {
      .modal-body {
        align-items: center;
        flex-direction: column;
        img {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }

    }
  }
}

//  Modal blook details
// ===================================================================

.blook-account-details {
  max-width: 400px;
  margin: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  img {
    //max-width: 230px;
    @include media-breakpoint-down(sm){
      max-height: 55vh;

    }
    width: auto;
    margin: auto;
  }
  li {
    list-style: none;
    margin: 5px 0 !important;
    @include media-breakpoint-down(sm){
      margin: 0 !important;
    }
  }
  a {
    text-decoration: underline;
  }
  .product-resume__details {
    &::after {
      display: none
    }
    position: relative;
    margin: 10px 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .blook-details {
    line-height: 1.8;
    //margin: 0 0 5px;
  }
}



.blook-modal-footer {

  @include media-breakpoint-up(md){
    height: 60px;
  }

  .order-btn {
    height: 100%;

    & * {
      height: 100%;
    }
    .btn {
      margin: 0;
      border-radius: 0;

      @include media-breakpoint-down(sm){
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.blook-account-actions {
  margin-left: 14px;

  @include media-breakpoint-down(sm){
    width: 100%;
    padding: 8px;
    text-align: center;
    margin: 0;
  }
  .btn-ico {
    margin: auto;
    padding: 0 8px;
    text-transform: none;
    svg {
      width: 20px;
      height: 20px;
      margin: 0 4px 0 0;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @include media-breakpoint-up(md) {
    .row {
      max-width: 400px;
      margin: auto;
    }
  }
}
