.page-head {
  padding: 55px 0 30px;
  h1,
  .h1-like {
    padding-top: 0;
  }
  &.page-head-banner {
    position: relative;
    min-height: 250px;
    background-color: $color1;
    margin-bottom: 20px;
    h1,
    .h1-like {
      position: absolute;
      top: 65%;
      right: auto;
      bottom: auto;
      left: 50%;
      color: #fff;
      transform: translate(-50%,0);
    }
    @include media-breakpoint-up(md) {
      min-height: 512px;
    }
  }
}

.page-content {
  margin-top: 40px;
  &.page-cms {
    font-size: 16px;
    font-family: $font2;
    line-height: 1.8;
    color: $darkcolor1;
    .chapo,
    .chapo p {
      margin: 0 auto 40px;
      padding-top: 40px;
      font: normal 300 18px/1.8 $font1;
      color: $color2;
    }
    li,
    p {
      font-size: 16px;
      font-family: $font2;
      line-height: 1.8;
      color: $darkcolor1;
    }
    img {
      margin: 30px auto;
      text-align: center;
      @include media-breakpoint-up(md) {
        &.align-right {
          float: right;
          margin-left: 30px;
          margin-right: percentage(-(1 / $grid-columns));
        }
        &.align-left {
          float: left;
          margin-right: 30px;
          margin-left: percentage(-(1 / $grid-columns));
        }
      }
    }
  }
  .table-like {
    margin: 40px auto;
    & > div {
      padding: 30px;
      border-bottom: 3px solid $color4;
      text-align: center;
      &:last-child {
        border: 0;
      }
    }
    img {
      margin: 0 auto 15px;
    }
    span {
      display: block;
      margin-bottom: 20px;
      font: normal 300 12px/1.1 $font1;
      color: $darkcolor2;
    }
    .title {
      margin: 0 auto 30px;
      font-size: 24px;
    }
  }
  @include media-breakpoint-up(md) {
    & > .container {
      max-width: 780px;
    }
    .table-like {
      border: 3px solid $color4;
      & > div {
        border: 0;
        border-right: 3px solid $color4;
      }
    }
  }

}
