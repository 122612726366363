.text-tag {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 11px;
  font-family: $font1;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  background: #000;
  &.positif {
    color: #000;
    background: #fff;
  }
  &.tag-color-3 {
    background: #c4bfb5;
  }
  &.small-tag {
    padding: 2px 8px;
    font-size: 9px;
  }
  &.product-tag {
    background: none;
    padding: 0;
    text-transform: none;
    color: currentColor;
  }
}
