.bloc-link {
  display: block;
  padding: 40px 20px;
  color: #fff;
  background: $color1;
  &:focus,
  &:hover {
    text-decoration: none;
  }
  .title {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    color: #fff;
  }
  .text {
    text-align: left;
    font-size: 14px;
    font-family: $font2;
  }
  &.bloc-link-color1 {
    color: #fff;
    background: $color1;
    .title {
      color: #fff;
    }
  }
  &.bloc-link-color3 {
    color: $color1;
    background: $color3;
    .title {
      color: $color1;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 60px;
  }
}
