.bottom-link {
  margin: 50px auto;
  text-align: center;
  ul {
    margin: 0 auto 20px;
    padding: 0;
  }
  li {
    display: inline-block;
  }
  a {
    display: block;
    text-transform: uppercase;
  }
  @include media-breakpoint-up(md) {
    margin-top: 120px;
  }
}

.bottom-link__first {
  li {
    position: relative;
    margin: 0 15px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 14px;
      top: 50%;
      right: -17px;
      background: rgba($color1, .3);
      transform: translate(0,-52%);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  a {
    display: block;
    font-size: 14px;
    color: $color1;
  }
}

.bottom-link__second {
  li {
    margin: 0 10px;
  }
  a {
    display: block;
    font-size: 11px;
    color: $darkcolor2;
  }
}


.site-footer {
  padding: 80px 20px 42px;
  color: #fff;
  background: $color2;
  .site-main-logo {
    color: #fff;
    width: 100%;
    margin-bottom: 40px;
  }
  svg {
    margin-bottom: 10px;
  }
  .baseline {
    color: #fff;
    font-size: 12px;
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
}

.site-social-network {
  text-align: center;
  .phrase {
    margin-bottom: 30px;
    text-align: center;
    font-size: 22px;
    font-family: $font2;
    color: $color1;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  svg {
    width: 30px;
    height: 30px;
  }
  a {
    display: block;
    margin: 0 15px;
    color: #575354;
    transition: .4s color;
    &:focus,
    &:hover {
      color: #828080;
    }
  }
  li {
    display: inline-block;
  }

}

.site-footer__colophon {
  margin-top: 70px;
  .site-options {
    text-align: center;
    padding-left: 0;
    margin-bottom: 60px;
  }
  .copyright {
    opacity: .35;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    p {
      margin-bottom: 0;
      color: $lightcolor2;
    }
  }
  li {
    display: inline-block;
    width: 45%;
    &:first-child {
      margin-right: 15px;
    }
  }
  .form-select {
    &::after {
      top: 18px;
      right: 16px;
      width: 10px;
      height: 6px;
    }
  }
  select {
    border-radius: 6px;
    border: 1px solid #4d4a4b;
    width: 100%;
    height: auto;
    cursor: pointer;
    padding: 10px 40px 10px 16px;
    font-size: 11px;
    text-transform: uppercase;
    color: $lightcolor2;
    border-color: $lightcolor2;
    background-color: transparent;
  }
  @include media-breakpoint-up(sm) {
    .copyright {
      text-align: left;
    }
    .site-options {
      text-align: right;
      margin-bottom: 0;
    }
    li {
      width: auto;
    }
    select {
      width: auto;
    }
  }
}
