.form-step {
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: space-around;
  margin: 65px auto 0;
  padding: 0 0 100px;
  counter-reset: step;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 28px;
    left: 0;
    height: 4px;
    background: #efefef;
  }
  .step {
    position: relative;
    list-style: none;
    padding: 0;
    letter-spacing: 1px;
    font-family: $font1;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: $darkcolor2;
    color: rgba($darkcolor2, .5);
    text-align: center;
    width: 25%;
    & > span,
    a {
      position: relative;
      padding-bottom: 22px;
      color: $darkcolor2;
      color: rgba($darkcolor2, .5);
      &::after {
        content: '';
        display: block;
        z-index: 3;
        position: absolute;
        bottom: -1px;
        left: 50%;
        height: 14px;
        width: 14px;
        background: #fff;
        border-radius: 50%;
        border: 3px solid #efefef;
        transform: translate(-50%,0);
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .text {
      &::before {
        content: counter(step);
        counter-increment: step;
      }
    }
    &.done {
      color: $darkcolor2;
      &::after {
        z-index: 2;
        content: '';
        position: absolute;
        height: 4px;
        top: 28px;
        right: 0;
        left: -100%;
        background: $color1;
      }
      a {
        color: $darkcolor2;
        &::after {
          background: $color1;
          border-color: $color1;
        }
      }
    }
    &.active {
      color: $color1;
      &::after {
        z-index: 2;
        content: '';
        position: absolute;
        height: 4px;
        top: 28px;
        right: 49%;
        //left: 0;
        left: -100%;
        background: $color1;
      }
      a {
        color: $color1;
        &::after {
          border-color: $color1;
        }
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
  &.logged-in {
    .step {
      width: 33.33333%;
    }
  }
}
