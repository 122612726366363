.cart-item {
  position: relative;
  margin-bottom: 30px;
  @include media-breakpoint-up(md) {
    &:first-child {
      border-top: 1px solid #e6e3e3;
    }
    margin-bottom: 2px;
  }
}

.cart-item__number {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    background: #e6e3e3;
    span {
      opacity: .7;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.cart-item__top {
  padding: 15px 0 0;
  margin-bottom: 20px;

  .img-product {
    float: left;
  }
  .content {
    overflow: hidden;
    padding-left: 15px;
    line-height: 1.2;
  }
  .product {
    font-size: 13px;
    font-weight: 400;
    color: $darkcolor4;
  }
  .title {
    display: block;
    font-size: 19px;
    font-weight: 400;
    color: $color1;
  }
  .author {
    font-size: 16px;
    font-weight: 400;
    font-family: $font2;
    color: $darkcolor1;
  }
  .product-resume__details {
    opacity: .6;
    display: block;
    clear: both;
    position: relative;
    padding: 0;
    margin-bottom: 20px;
    li {
      display: inline-block;
      font-size: 11px;
      margin: 10px 15px 0 0;
    }
    img {
      width: 13px;
      margin-right: 3px;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 15px 12px 8px;
    .product {
      display: block;
      font-size: 11px;
      margin-bottom: 5px;
    }
    .product-resume__details {
      display: inline-block;
      clear: none;
      margin: 0 0 0 15px;
      left: auto;
      li {
        &:nth-child(0n+3) {
          clear: none;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-left: 20px;
  }
}

.cart-item__content {
  @include media-breakpoint-up(lg) {
    padding-left: 50px;
  }
}

.table-like__body {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 15px;
  justify-content: center;
  border-bottom: 2px solid #e6e3e3;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: -2px;
    left: 0px;
    width: 15px;
    background: #e6e3e3;
  }
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-left: 0;
    &::before {
      top: 29px;
      right: 0;
      bottom: auto;
      left: 0;
      width: auto;
      height: 1px;
    }
  }
}

.table-like__td {
  border-top: 1px solid #e6e3e3;
  padding: 12px 5px 7px 15px;
  width: 25%;
  font-size: 14px;
  &.type {
    width: 100%;
    padding: 12px 16px;
  }
  .checkbox-button {
    label {
      font-size: 10px;
      padding: 6px 10px 6px 30px;
      margin: 0 10px 0 0;
      svg {
        left: 10px;
      }
    }
  }
  &.quantity {
    input {
      display: inline-block;
      position: relative;
      top: -5px;
      max-width: 44px;
      color: $color2;
      padding: 3px 10px;
      font-size: 14px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #d8d8d8;
    }
  }
  .see-blook-link {
    font-size: 13px;
    text-transform: none;
    color: $darkcolor1;
    text-decoration: underline;

    &:hover {
      color: $RedCarnation;
    }
  }
  &.suppr-col {
    text-align: center;
  }
  .label {
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-size: 10px;
    color: $darkcolor4;
    text-transform: uppercase;
  }
  .btn-refresh,
  .btn-suppr {
    display: inline-block;
    color: #999;
    margin-top: 25px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .btn-refresh {
    margin: 0 0 0 5px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .resume-cart & {
    width: 33%;
    &.type {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    width: auto;
    text-align: right;
    border-top: 0;
    padding: 12px 0 7px 15px;
    width: 14%;
    .label {
      margin-bottom: 23px;
    }
    &.suppr-col {
      width: 8%;
      .btn-suppr {
        margin-top: 36px;
      }
    }
    &.type {
      width: 51%;
      padding: 12px 0 7px 12px;
      text-align: left;
      .label {
        margin-bottom: 19px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.type {
      padding: 12px 0 7px 20px;
    }
  }
}


//  Code promo
// ===================================================================

.discount-code {
  padding: 0 20px 30px;
  border-bottom: 2px solid #d8d8d8;
  label {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
  }
  .form-group {
    margin: 0;
  }
  .group-input {
    display: flex;
    width: 100%;
  }
  input {
    flex: 1 1 auto;
    padding: 8px 15px 10px;
    font-size: 12px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
  }
  .btn {
    flex: 0;
    padding: 13px 18px 12px;
    margin: 0;
    font-size: 11px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @include media-breakpoint-up(md) {
    padding: 20px;
    .form-group {
      display: flex;
      align-items: center;
    }
    label {
      width: 60%;
      margin-bottom: 0;
      text-align: left;
    }
    .group-input {
      width: 40%;
    }
    input {
      width: 60%;
      font-size: 12px;
    }
    .btn {
      width: 40%;
      font-size: 10px;
    }
  }
}

.discount-code {
  #promo_form {
    button[type="submit"] {
      margin-left: -10px;
    }
  }
  #promo_delete {
    button[type="submit"] {
      border-radius: 10px;
    }
  }
}

.type_row {
  border-bottom: 2px solid #d8d8d8;
  margin-bottom: 10px;
}

//  Total
// ===================================================================

.cart-total {
  padding: 18px 15px;
  .row {
    margin-top: 5px;
    &:last-child {
      span {
        font-size: 15px;
        font-weight: 600;
        &.price {
          font-size: 24px;
        }
      }
    }
    .col-6:last-child {
      text-align: right;
    }
  }
  span {
    font-size: 15px;
    font-weight: 400;
  }
  @include media-breakpoint-up(md) {
    padding: 18px 20px;
    .row {
      &:last-child {
        span {
          font-size: 20px;
          &.price {
            font-size: 24px;
          }
        }
      }
    }
    span {
      font-size: 14px;
    }
  }
}

//  Address
// ===================================================================

.address-listing {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6e3e3;
  padding-top: 40px;
  @include media-breakpoint-up(md) {
    padding-top: 60px;
    padding-bottom: 40px;
    flex-direction: row;
  }
}

.address-infos {
  width: 100%;
  padding: 30px 20px 30px 40px;
  font-weight: 300;
  font-size: 14px;
  border-top: 1px solid #e6e3e3;
  &:first-child {
    border: 0;
  }
  h3 {
    margin-top: 0;
    font: normal 400 18px $font1;
    color: $color1;
  }
  @include media-breakpoint-up(md) {
    width: 50%;
    border-top: 0;
    border-left: 1px solid #e6e3e3;
    padding: 10px 20px 10px 40px;
  }
}

//  Choose payment
// ===================================================================

.payment-listing {
  display: flex;
  margin: 0 auto 40px;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    margin-top: 40px;
    flex-direction: row;
  }
}

.payment-listing__item {
  width: 100%;
  a, button {
    display: block;
    padding: 40px 20px;
    margin: 15px;
    text-align: center;
    color: $color2;
    border: 4px solid $color1;
    transition: all .3s;
    &:hover,
    &:focus {
      border-color: $color4;
    }
  }
  li {
    display: inline-block;
    margin: 20px 0 0;
  }
  img {
    width: 64px;
    height: auto;
  }
  @include media-breakpoint-up(sm) {
    width: 50%;
  }
}

.blookcloud-suggestion {
  padding-left: 20px;
  padding-top: 15px;
  //font-size: 12px;
  font-size: 0.75rem;
  .btn-link {
    text-transform: none;
  }
}
