.flipbook-container {
  position: relative;
  margin: 0 auto 30px;

  &.twitter{
    max-width: 866px;
  }
}

.flipbook-content {
  background-color: #f5f2ed;
  padding: 20px 0;
}

#flipbook {
  position: relative;
  width: auto;
  margin: 0 auto 30px;
  max-width: 400px;
  width: 84vw;

  @include media-breakpoint-up(md) {
    max-width: 1182px;
    min-width: 560px;
    padding: 0 60px;
    margin-bottom: 70px;
    width: 100%;
    .glider {
      overflow: hidden
    }
  }

  img[src*=data]{
    height: 100%;
    width: auto !important;
  }

  .glider-slide  {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 10;
      box-shadow: 0px 10px 47px 0 rgba(0,0,0,.1);
    }

    &:first-child, &:last-child{
      border-top: none;
      border-bottom: none;
      &:before{
        content: none;
      }
    }

    &:first-child {
      :after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: -1px;
        height: 100%;
        width: 1px;
        z-index: 200;
        background: white;
      }
    }
  }

  .slick-list.bordered {
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
  }

  .slick-arrow {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &:focus, &:hover {
      outline: none;
      background-color: #b5b1aa;
    }
    width: 50px;
    height: 50px;
    position: absolute;
    background-color: #c8c3bb;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    cursor: pointer;
    bottom: 20px;

    // @include media-breakpoint-up(xl) {
    //   bottom: 134px;
    // }

    svg {
      width: 50%;
      height: 100%;
      color: white;
    }

    &.slick-prev {
      left: 0;
    }
    &.slick-next {
      right: 0;
    }
  }
}

.horizontal-preview-btn {
  cursor: pointer;
  width: 53px;
  height: 50px;
  position: absolute;

  @include media-breakpoint-only(xs){
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    border-radius: 0 100px 100px 0;
    background-color: rgba(0,0,0,.5);
    color: white;
     &:hover, &:focus {
      color: white;
    }
  }

  @include media-breakpoint-up(sm){
    left: 0;
    bottom: 80px;
    background-color: #c8c3bb;
    color: #fff;
    border-radius: 5px;
    &:hover, &:focus {
      color: #fff;
      background-color: #b5b1aa;
    }

  }

  svg {
    width: 20px;
    height: 20px;
    margin: 15px 15px 8px 15px;
  }
}

.flipbook-arrow {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  display: flex;
  z-index: 10;
  width: 50px;
  height: 50px;
  justify-content: center;
  pointer-events: none;
  background: rgba(0,0,0,.5);
  border-radius: 100px 0 0 100px;

  svg {
    width: 26px;
    color: white;
  }
}

.flipbook-actions {
  width: auto;
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  .flipbook-share {
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm){
      margin-bottom: 30px;
    }

    .flipbook-share-label{
      font-size: 14px;
      display: block;
      margin-bottom: 15px;
    }
    .flipbook-share-link{
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      li{
        margin: 0 20px;
      }
      a{
        display: flex;
        align-items: center;
        color: $color2;
      }
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  .blook-link{
    // flex: 0 0 auto,
    // padding: 10px 25px;
    // background: $color1;
    // color: white;
    // font-size: 14px;
  }

}
