.presentation-blookcloud {
  .pricing {
    display: flex;
    margin-bottom: 50px;
    margin-top: 50px;
    .price {
      border-radius: 50%;
      background-color: $color1;
      color: white;
      min-width: 120px;
      min-height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
