.navigation-overlay {
  z-index: 8000;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  pointer-events: none;
  visibility: hidden;
  background: rgba(#0f0f0f, .95);
  transition: height .35s ease-out,
              visibility ease-out .4s;
  text-align: center;
  &.active {
    visibility: visible;
    transform: scale(1);
    pointer-events: auto;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(sm){
      overflow: hidden;
    }
    .container,
    .site-social-network ul,
    .navigation-overlay__other-links,
    .navigation-overlay__links li {
      opacity: 1;
      transform: none;
    }
  }

  .navigation-overlay__logo {
    padding: 20px 0 20px;
    color: white;

    @include media-breakpoint-up(md) {
      padding: 35px 0 40px;
    }

    svg {
      width: 100px;
      height: 30px;
      margin: 0 auto;
      color: white;

      @include media-breakpoint-up(md) {
        width: 153px;
        height: 40px;
      }
    }

     .baseline {
      @extend %baseline;
    }
  }
  .btn {
    position: absolute;
    top: 11px;
    left: 14px;
    padding: 10px;
    margin: 0;
  }
  .site-social-network {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .container {
    position: relative;
    opacity: 0;
    height: 100vh;
    max-width: 700px;
    transition: opacity .25s ease-out;
  }
  .site-social-network ul {
    opacity: 0;
    transform: translate(0,-8px);
    transition: opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.45s,
                transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.45s;
  }
  @include media-breakpoint-up(md) {
    .btn {
      top: 39px;
      left: 32px;
    }
    .site-social-network {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%,0);
    }
  }
}

.navigation-overlay__links {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}

.navigation-overlay__primary-links {
  font-weight: 700;
  margin: 80px 0 20px;
  padding: 0 32px;
  li {
    margin: 30px 0 0;
    list-style: none;
    opacity: 0;
    transform: translate(0,-8px);
    transition: opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s,
                transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
    &:nth-child(2) {
      transition-delay: 300ms, 300ms;
    }
    &:nth-child(3) {
      transition-delay: 350ms, 350ms;
    }
  }
  a {
    position: relative;
    font-size: 24px;
    line-height: 1;
    color: $color1;
    border-bottom: 2px solid rgba(246, 78, 96, .3);
    transition: border-bottom-color .2s linear;
    &::after {
      content: '';
      display: inline-block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 3px;
      width: 100%;
      background: #fff;
      transition: all .4s;
      content: none; /* hover disabled */
    }
    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: $color1;
      &::after {
        opacity: .1;
        bottom: -4px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    margin: 80px 0;
    padding: 0;
    a {
      font-size: 36px;
    }
  }
}


.navigation-overlay__other-links {
  margin-top: 40px;
  padding-bottom: 50px;
  opacity: 0;
  transform: translate(0,-8px);
  transition: opacity 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s,
              transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  ul {
    padding: 0;
  }
  li {
    margin: 15px 0 0;
    list-style: none;
  }
  a {
    opacity: .7;
    font-size: 14px;
    font-weight: 300;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }
}
