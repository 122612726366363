.blookup-card {
  position: relative;
  display: block;
  margin: 130px auto 50px;
  padding: 100px 30px 30px;
  box-shadow: 0 0 0 5px $color1;
  text-decoration: none;
  transition: box-shadow .5s;
  &:focus,
  &:hover {
    text-decoration: none;
    box-shadow: 0 0 0 5px $color3;
    .title {
      color: $color1;
    }
    img {
      transform: translate(-50%,-50%) scale(1);
    }
  }
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    max-width: 275px;
    margin: auto;
    transition: transform .5s;
    transform: translate(-50%,-50%) scale(.99);
  }
  .title {
    display: block;
    margin-bottom: 5px;
    letter-spacing: -1px;
    color: $color2;
    font-size: 20px;
    font-weight: 500;
    transition: color .5s;
  }
  .text {
    color: $darkcolor2;
    font-family: $font2;
    font-size: 14px;
  }
  @include media-breakpoint-up(md) {
    max-width: 820px;
    margin: 130px auto 75px;
    padding: 65px 60px 65px 300px;
    &:focus,
    &:hover {
      img {
        transform: translate(-15%,-50%) scale(1);
      }
    }
    img {
      max-width: 315px;
      top: 50%;
      left: 0;
      transform: translate(-15%,-50%) scale(.99);
    }
  }
  @include media-breakpoint-up(lg) {
    box-shadow: 0 0 0 8px $color1;
    padding: 100px 60px 100px 410px;
    &:focus,
    &:hover {
      box-shadow: 0 0 0 8px $color3;
    }
    .title {
      font-size: 24px;
    }
    img {
      max-width: 435px;
    }
  }
}
