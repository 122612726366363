.pre-import-layout {
	label{
		height: 100%;
	}
	.blog-list {
	  border-radius: 4px;
	  display: flex;
	  justify-content: flex-start;
	  font-size: 13px;
	  border: 1px solid $color3;
	  cursor: pointer;
	  padding: 15px;
	  height: 100%;
	  word-break: break-word;

	  &:hover {
	  	border-color: darken($color3, 20%);
	  }

	  input[type=radio]{
	  	display: none;
	  }

	  p{
	  	margin-bottom: 0;
	  	color: $darkcolor2;

	  	&:not(.title){
	  		margin: 1px 0;
	  	}
	  }

		.avatar {
		  margin-right: 15px;
		  flex: 0 0 auto;
		  width: 70px;

		  @include media-breakpoint-up(md){
		  	width: 80px;
		  	margin-right: 20px;
		  }
		}

		.title {
		  font-weight: 800;
		  font-size: 14px;
		  color: $BlackMine;
		}
	}

	.blog-selected{
		.blog-list{
			border-color: $color1;
		}
	}

	.fas{
		margin-right: 10px;
		font-size: 12px;
		color: $darkcolor1;
	}

	.blog-account-import{
		.blog-list{
			cursor: default;
			padding: 0 10px;
			border: none;
			&:hover {
		  	border-color: $color3;
		  }
		}
	}
}

.progress-bar{
	margin: 0 auto;
	width: 90%;
	display: block;
	text-align: center;
}
.bar {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	height: 10px;
	width: 100%;
	display: block;
	background: $color3;
	border-radius: 4px;
	overflow: hidden;

	&::-webkit-progress-value {
		background: $color3;
	}

	&::-moz-progress-bar {
		background: $color1;
	}

	&::-webkit-progress-value {
		background: $color1;
	}

	&::-webkit-progress-bar {
		background: $color3;
	}
}


.pdf-import {


	.label-pdf{
		top: 0 !important;
	}

	.input-file-container {
	  position: relative;

		.input-file-trigger {
		  display: block;
		  padding: 12px 20px;
		  background: $color3;
		  color: $darkcolor2;
		  font-size: 12px;
		  text-transform: uppercase;
		  font-weight: 600;
		  border-radius: 6px;
		  transition: all .2s;
		  cursor: pointer;
		}
		[type=file] {
		  position: absolute;
		  top: 0;
		  left: 0;
		  opacity: 0;
		  padding: 14px 0;
		  cursor: pointer;
		}
		[type=file]:hover + .input-file-trigger,
		[type=file]:focus + .input-file-trigger,
		.input-file-trigger:hover,
		.input-file-trigger:focus {
		  background: $color1;
		  color: white;
		}

	} 

	.file-return {
	  margin: 0;
	  padding-left: 10px;
	  font-size: 13px;
	  color: $darkcolor2;
	}
}