.separator {
	margin: 0;

	&:before,
	&:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 75px;
		height: 1px;
		background-color: #979797;
	}

	&:before {
		margin-right: 10px;
	}

	&:after {
		margin-left: 10px;
	}
}